import utils, { openLink, showInstance } from '../../common/components/utils.js';
import entityManager from '../../common/components/entityManager.js';
import PrimitiveEntityType from '../../common/enums/primitiveEntityType';
import {convertSelectionsToIds,initFiltersEntityTable} from '../../utils/tableUtils'
import TableState from '../../common/components/tableState';
import DocumentRegisterState from '../../common/enums/documentRegisterState';
import Constants from '../../common/models/constants.js';
import {translate, translateToMls} from '../../common/service/stringResourceService'
import cjTabs from '../../common/components/cjTabs';
import journalPresenter from '../../entity/views/journalPresenter';
import MultilingualString from '../../common/models/multilingualString';
import OpenMode from '../../common/enums/openMode.js';



function getOldTableData():object{
	let res={
		getTableFilterFields:function(){
			return [
				{fieldId: Constants.ID_FIELD_DOCUMENT_DATE_AND_TIME, field: 'documentDateTime'},
				{fieldId: Constants.ID_FIELD_DOCUMENT_REGISTER_STATE, field: 'state'},
				{fieldId: Constants.ID_FIELD_TYPE, field: 'entityTypeId', name: translateToMls('type').toServerJSON()}
			]
		}
	}
	res.newTable=true
	return res
}

var TimelineTable = Backbone.View.extend({

	el: '.timelineTable',

	events: {
		'click button.apply-in-collection' : 'applyOne',
		'click .show-registers-in-collection': 'showRegisters'
	},

	initialize: function (options) {
		this.lastValue = null
		if (!options.initFromPage){
			app.cjTabs = cjTabs
			app.cjTabs.createTab({$el : $('.cj-tab') , title: translate('documenttimeline'), url: '/' + app.currentLanguage + '/documentTimeline/view'})
		} else {
			this.c = options.context
		}
		this.buttonsToEneableOnCheck=[]
		var that = this;
		this.data=[]
		this.id="timelineTable"
		this.tempel$=$(this.el)
		this.tempel$.wrap(`<div class="highViewport newTableWrapper table-${this.id}"><div>`)
		this.oldTable=getOldTableData()
		let op = {
			rows: this.data,
			columns: [
				{type:'checkbox'},
				{
					field: 'state',
					sorter: true,
					width:41,
					formatter:app.formattersAndStyles.actionsFormatter
				},
				{
					field: 'documentDateTime',
					sorter: true,
					title: translate('system.field.document.timestamp.name'),
					formatter: app.formattersAndStyles.primitiveFormatter({primitiveType:PrimitiveEntityType.TIMESTAMP})
				},
				{
					field: 'serialNumber',
					title: translate('system.field.serial.number.name'),
					formatter: app.formattersAndStyles.primitiveFormatter({primitiveType:PrimitiveEntityType.SYSTEM_STRING})
				},
				{
					field: 'entityTypeId',
					title: translate('type'),
					formatter: (value, row) => `<span class="btn-link ref" data-objectId="${row.metaObjectId}"
					data-type="${value}" data-kind=${OpenMode.NEW_CJ_TAB} data-index="true" style="cursor:pointer;">${MultilingualString.getCurrentValue(app.types.get(value).name())}</span>`
				}
			],
			table: {
				loadMore: (data) => {return this.getMoreDataForTable2({
					size: data.size,
					loadItems: data.loadItems,
					loadFilteredSize: data.loadTotal,
					loadTotalSize: false,
					clear: data.clear
				},this)}
			},
			events: {
				selectionChanged: function(s){
					that.onCheckAndUncheck();
				},
				rowClicked: function(row,e){
						that.onClickCell(row,e)
				}
			}
		}
		initFiltersEntityTable({
			el: this._get('filters').first(),
			table: this,
			fields: app.fields,
			types: app.types,
			model: app.model,
			typeId: app.typeId
		})
		this.state = new TableState(this);
		this.state.apply()
		this.newTable=TableConnector(this.el, op).connect();

		var selectionLength = this.newTable.getSelection().length;
		this._get('selectedEntities').text(selectionLength);
		this.setElement($(`div.table-${this.id}`)[0])

		this._get('applyMany').first().confirm({
			confirm: function (button) {
				that.applyMany(that.getSelectedIds());
			}
		});
		this._get('relieveMany').first().confirm({
			confirm: function (button) {
				that.relieveMany(that.getSelectedIds());
			}
		});
		 this.buttonsToEneableOnCheck.push('applyMany');
		 this.buttonsToEneableOnCheck.push('relieveMany');
	},
	render: function(){
	},

	filter: function (filters, strict) {
		if (strict || this.filters && this.filters.getFilters().length === 0 && filters.length !== 0) {
			this.filters.loadFilters(filters);
		}
	},

	setExtendedMode: function(){},

	getSelectedIds: function(){
		return convertSelectionsToIds(this.newTable.getSelection())
	},
	_get: function (shortId) {
		if (this.context){
			return this.c.$el.find('.' + this.id + '_' + shortId);
		}else {
			return $('.' + this.id + '_' + shortId);
		}
	},
	getStorageId: function () {
		return this.id+'f';
	},
	onCheckAndUncheck: function () {
		var that = this;
		var selectionLength = this.newTable.getSelection().length;
		this._get('selectedEntities').text(selectionLength);
		$(this.buttonsToEneableOnCheck).each(function (i, value) {
			that._get(value).toggleClass('disabled', !selectionLength);
		});
	},
	showRegisters: function (e) {
		e.stopPropagation();
		var id = $(e.currentTarget).attr('data-document-id');
		if (id) {
			let title;
			app.entityManager.fetchStringView(null, id).then((data) => {
				title = data;
				app.cjTabs.createTab({
					documentId: id,
					title: title + ' ' + translate('postings'),
					onRender: (options) => {
						journalPresenter.present({
							$el: options.$el,
							documentId: id
						})
					}});
			});
		}
	},
	filtersChanged: function (event) {
		if (this.newTable){
			this.lastValue = null
			this.newTable.resetMore();
		}
	},
	updateDownloaded: function (length) {
		if (length != -1){
			this._get("allEntities").text(length);
		}
	},
	getMoreDataForTable2: function (moreStructure, table) {
		$.extend(moreStructure, {
			filters: this.filters&&this.filters.toServerJSON()||null,
			lastValue: moreStructure.clear ? null : this.lastValue
		});
		let order={}
		if (table.newTable && table.newTable.getOrder()){
				if (table.newTable.getOrder().order=="asc"){
					order.ascendingOrder=true;
				}else if (table.newTable.getOrder().order=="desc") {
					order.ascendingOrder=false;
				}
				order.field={id:this.privateGetFieldId(table.newTable.getOrder().field)}
		} else {
			order = {
				ascendingOrder: false,
				field: {id: Constants.ID_FIELD_DOCUMENT_DATE_AND_TIME}
			}
		}
		moreStructure.order=order
		return entityManager.fetchPageOfDocuments(moreStructure, app.documentTimelineId)
			.then((pair)=> {
				this.updateDownloaded(pair.two)
				if (moreStructure.loadItems) {
					this.lastValue = pair.one[pair.one.length - 1]
					this.lastValue = this.lastValue && {
						id: this.lastValue.documentRegister.id,
						documentDateTime: this.lastValue.documentRegister.value
					} || null
				}
				return	{
					items: pair.one,
					totalSize: pair.two
			 	}
	  })
	},
  privateGetFieldId (field) {
    return {
			'documentDateTime': Constants.ID_FIELD_DOCUMENT_DATE_AND_TIME,
			'state': Constants.ID_FIELD_DOCUMENT_REGISTER_STATE
    }[field]
  },
	applyOne(e){
		this.applyMany([e.currentTarget.attributes.id.value])
	},

	applyMany: function (ids) {
		utils.postRequest(ids, app.urls.applySelectedTimeLine).then(() => {
				this.data.forEach((elem) => {	if (ids.includes(elem.id)) elem.documentRegister.state = DocumentRegisterState.POSTED });
			}).catch(function (e) {
				utils.error(e);
			});
	},

	relieveMany: function (ids) {
		utils.postRequest(ids, app.urls.relieveSelectedTimeLine).then(() => {
				this.data.forEach((elem) => {	if (ids.includes(elem.id)) elem.documentRegister.state = DocumentRegisterState.REVERTED });
			}).catch(function (e) {
				utils.error(e);
			});
	},
	fetchAndUpdateRow: function (id) {
		let index = this.data.findIndex((r) => {
			return r.id == id
		})
		if (index != -1){
			let moreStructure = {
				size: 1,
				loadItems: true,
				loadFilteredSize: false,
				loadTotalSize: false,
				loadDeleted: true,
				filters:[{
					field: {id: "140737488355566"},
					kind: "EQUAL",
					value: `{"id":${id}}`
				}],
				lastValue: null
			}
			entityManager.fetchPageOfDocuments(moreStructure, app.documentTimelineId).then((a) => {
				this.data.splice(index, 1, a.one[0])
			})
		}
	},
	onClickCell: function (row, e) {
		let target=$(e.target)
		if (!((target.parents('a')[0])||e.target.nodeName.toLowerCase()=='a')){
			let cell
			if (target.hasClass('table-cell')){
				cell=target
			} else {
				cell=target.parents('div.table-cell')
			}	if (!(cell.find('button')[0])){
				if (target.is('.ref')) {
					openLink(target, e);
				} else {
					showInstance({
						typeId: row.entityTypeId,
						objectId: row.id,
						openMode: OpenMode.NEW_CJ_TAB,
						onApply: () => {
							this.fetchAndUpdateRow(row.id)
						},
					})
				}
			}
		}
	}

});

export default TimelineTable;
