import Constants from '../models/constants';
import { UrlServicePrototype } from '../components/urlService';
import UsageReferrer from "../enums/usageReferrer";

const HOME_LINK = app.urls.home;

const openPath={
  [Constants.ID_TYPE_TYPE]:"entityType/details",
  [Constants.ID_TYPE_FIELD]:"entityField/update",
  [Constants.ID_TYPE_USER_ROLE]:"appUserAccount#",
  [Constants.ID_TYPE_TASK]:"entityTask",
  [Constants.ID_TYPE_APP_USER_ACCOUNT]:"appUserAccount#",
  [Constants.ID_TYPE_BLOCK]:"entityBlock/construct",
  [Constants.ID_TYPE_CATEGORY]:"#",
  [Constants.ID_TYPE_MODULE]:"#",
  [Constants.ID_TYPE_SHARED_STYLE]:"sharedStyle/update",
  [Constants.ID_TYPE_PERMISSION]:"permission/",
  [Constants.ID_TYPE_ENTITY_MENU]:"entityMenu/",
  [Constants.ID_TYPE_ENTITY_GENERATOR]:"entityGenerator",
  [Constants.ID_TYPE_DOCUMENT_TIMELINE]:"documentTimeline#",
  [Constants.ID_TYPE_MULTILINGUAL_STRING_RESOURCE]:"stringResources#",
  [Constants.ID_TYPE_CONFIGURATION]:"/",
  [Constants.ID_TYPE_VIEW]:"entityView/edit",
  [Constants.ID_TYPE_SYSTEM_INSTANCE]:"entityType/updateInstance",
  [Constants.ID_TYPE_API_ENTRY]:"apiEntry#",
  [Constants.ID_TYPE_PRIMITIVE_FORMATTER]:"primitiveFormatter#",
  [Constants.ID_TYPE_CSS_THEME]: "themeEditor/update",
  'APPLICATIONTYPE':"entity{0}/update",
  [Constants.ID_TYPE_ENTITY_ROUTE]: "configuration/update#routes",
  [Constants.ID_TYPE_CUSTOM_LIBRARY]: "customResources",
  [Constants.ID_TYPE_DATASOURCE_CONFIG]: 'dataSources'
}

const updatePath = {
  [Constants.ID_TYPE_APP_USER_ACCOUNT]: "appUserAccount/update",
  [Constants.ID_TYPE_PERMISSION]: "permission/update",
  [Constants.ID_TYPE_ENTITY_MENU]: "entityMenu/update",
  [Constants.ID_TYPE_DOCUMENT_TIMELINE]:"documentTimeline/update",
  [Constants.ID_TYPE_MULTILINGUAL_STRING_RESOURCE]: "stringResources/update",
  [Constants.ID_TYPE_CUSTOM_LIBRARY]: "customResources/update",
  [Constants.ID_TYPE_SCHEDULER]: "scheduler/update",
  [Constants.ID_TYPE_SHARED_STYLE]: "sharedStyle/update",
  [Constants.ID_TYPE_TYPE]: "entityType/update",
  [Constants.ID_TYPE_BLOCK]: "entityBlock/update",
  [Constants.ID_TYPE_FIELD]: "entityField/update",
  [Constants.ID_TYPE_API_ENTRY]: "apiEntry/update",
  [Constants.ID_TYPE_ENTITY_GENERATOR]: "entityGenerator/update",
  [Constants.ID_TYPE_MULTILINGUAL_STRING_RESOURCE]:"stringResources/update",
  [Constants.ID_TYPE_CONFIGURATION]:"configuration/update",
  [Constants.ID_TYPE_USER_ROLE]: "userRole/update",
  [Constants.ID_TYPE_REGISTER_INDEX] : "registerIndex/update",
  [Constants.ID_TYPE_PERMISSION_NETWORK_WHITELIST] : "whitelist/update",
  [Constants.ID_TYPE_PRIMITIVE_FORMATTER]:"primitiveFormatter/update",
  [Constants.ID_TYPE_MULTILINGUAL_STRING]: "multilingualString/update",
  [Constants.ID_TYPE_CSS_THEME]: "themeEditor/update"
}

const deletePath = {
  [Constants.ID_TYPE_CUSTOM_LIBRARY]: "customResources/delete",
  [Constants.ID_TYPE_BLOCK]: "entityBlock/delete",
  [Constants.ID_FIELD_BLOCK]: "entityBlock/deleteSelected",
  [Constants.ID_TYPE_USER_ROLE]: "userRole/deleteSelected",
  [Constants.ID_TYPE_SCHEDULER]: "scheduler/delete",
  [Constants.ID_TYPE_ENTITY_MENU]: "entityMenu/deleteSelected",
  [Constants.ID_TYPE_PERMISSION]: "permission/deleteSelected",
  [Constants.ID_TYPE_SHARED_STYLE]: "sharedStyle/deleteSelected",
  [Constants.ID_TYPE_DOCUMENT_TIMELINE]: "documentTimeline/deleteSelected",
  [Constants.ID_TYPE_FIELD]: "entityField/deleteSelected",
  [Constants.ID_FIELD_OWNER]: "entityField/{0}/deleteSelected",
  [Constants.ID_TYPE_ENTITY_GENERATOR]: "entityGenerator/deleteSelected",
  [Constants.ID_TYPE_API_ENTRY]:"apiEntry/deleteSelected",
  [Constants.ID_TYPE_CATEGORY]: "entityCategory/deleteSelected",
  [Constants.ID_TYPE_MODULE]: "module/deleteSelected",
  [Constants.ID_TYPE_TYPE]: "entityType/deleteSelected",
  [Constants.ID_TYPE_PERMISSION_NETWORK_WHITELIST] : "whitelist/deleteSelected",
  [Constants.ID_TYPE_PRIMITIVE_FORMATTER]: "primitiveFormatter/deleteSelected",
  [Constants.ID_TYPE_CSS_THEME]: "themeEditor/deleteSelected"
}

const createPath = {
  [Constants.ID_TYPE_PERMISSION]: "permission/create",
  [Constants.ID_TYPE_TYPE]: "entityType/create",
  [Constants.ID_TYPE_ENTITY_MENU]: "entityMenu/create",
  [Constants.ID_TYPE_DOCUMENT_TIMELINE]:"documentTimeline/create",
  [Constants.ID_TYPE_CUSTOM_LIBRARY]: "customResources/create",
  [Constants.ID_TYPE_SCHEDULER]: "scheduler/create",
  [Constants.ID_TYPE_EVENT_HANDLER]: "eventHandler/create",
  [Constants.ID_TYPE_USER_ROLE]: "userRole/create",
  [Constants.ID_TYPE_SYSTEM_INSTANCE]: "entityType/createInstance",
  [Constants.ID_TYPE_FIELD]: "entityField/create",
  [Constants.ID_TYPE_SHARED_STYLE]: "sharedStyle/create",
  [Constants.ID_TYPE_API_ENTRY]: "apiEntry/create",
  [Constants.ID_TYPE_PERMISSION_NETWORK_WHITELIST] : "whitelist/create",
  [Constants.ID_TYPE_REGISTER_INDEX] : "registerIndex/create",
  [Constants.ID_TYPE_PRIMITIVE_FORMATTER]: "primitiveFormatter/create",
  [Constants.ID_TYPE_CSS_THEME]: "themeEditor/create",
  'APPLICATIONTYPE': "entity{0}/create",
  [Constants.ID_TYPE_ENTITY_GENERATOR]: "entityGenerator/create",
  [Constants.ID_TYPE_DATASOURCE_CONFIG]: 'dataSources/create'
}

const dependenciesPath = {
  [Constants.ID_TYPE_PERMISSION]: "permission/dependencies",
  [Constants.ID_TYPE_ENTITY_MENU]: "entityMenu/dependencies",
  [Constants.ID_TYPE_CATEGORY]: "entityCategory/dependencies",
  [Constants.ID_TYPE_MODULE]: "module/dependencies",
  [Constants.ID_TYPE_FIELD]: "entityField/dependencies",
  [Constants.ID_FIELD_OWNER]:"entityField/{0}/dependencies",
  [Constants.ID_TYPE_USER_ROLE]: "userRole/dependencies",
  [Constants.ID_TYPE_VIEW]: "entityView/dependencies",
  [Constants.ID_TYPE_BLOCK]: "entityBlock/dependencies",
  [Constants.ID_TYPE_TYPE]: "entityType/dependencies",
  [Constants.ID_TYPE_MULTILINGUAL_STRING_RESOURCE]: "stringResources/dependencies",
  [Constants.ID_TYPE_ENTITY_GENERATOR]: "entityGenerator/dependencies",
  [Constants.ID_TYPE_PERMISSION_NETWORK_WHITELIST] : "whitelist/dependencies",
  [Constants.ID_TYPE_CUSTOM_LIBRARY]: "customResources/dependencies",
  [Constants.ID_TYPE_SHARED_STYLE]: "sharedStyle/dependencies",
  [Constants.ID_TYPE_PRIMITIVE_FORMATTER]:"primitiveFormatter/dependencies",
  [Constants.ID_TYPE_CSS_THEME]: "themeEditor/dependencies"
}

function useTemplate(builderId, configurationId, categoryId) {
  return HOME_LINK + 'merge/mergeTemplate?builderId=' + builderId + '&configurationId=' + configurationId + '&categoryId=' + categoryId;
}

function isPredefinedType (typeId) {
	return Constants.ID_SEQUENCE_OFFSET.lte(typeId) && Constants.ID_SEQUENCE_OFFSET.mul(2).gte(typeId)
}

function isPredefinedReportType (typeId) {
	return Constants.ID_TYPE_CLEANER_REPORT == typeId
}

function openLink(typeId, arg, skipHomeLink){
  let link = skipHomeLink ? '/' : HOME_LINK
  if (!isPredefinedType(typeId) || isPredefinedReportType(typeId)){
    return link + resolver(openPath, 'APPLICATIONTYPE',[typeId]) + (arg ? '/'+ arg : '')
  }
  switch(typeId) {
  case Constants.ID_TYPE_CONFIGURATION:
    return arg ? ('/' + app.currentLanguage + openPath[typeId] + arg) : HOME_LINK  + 'configuration/update'
  case Constants.ID_TYPE_PERMISSION:
  case Constants.ID_TYPE_ENTITY_MENU:
    return link + openPath[typeId] + arg
  default:
    return link + openPath[typeId] + (arg ? '/'+ arg : '')
  }
}

function deployLink(appId, updateMode, projectJarId, overrideConfiguration, skipSystemSeed, forceFullSchemaUpdate) {
  let link = HOME_LINK + 'deploy?updateMode=' + updateMode
	+ '&overrideConfiguration=' + overrideConfiguration
	+ '&skipSystemSeed=' + skipSystemSeed
	+ '&forceFullSchemaUpdate=' + forceFullSchemaUpdate;

  if (appId) {
	link = link + '&appId=' + appId;
  }

  if (projectJarId != null) {
    link = link + '&projectJarId=' + projectJarId;
  }

  return link;
}

function downloadLink(appId, updateMode, projectJarId){
  let link = HOME_LINK + 'downloadApp?appId=' + appId + '&updateMode=' + updateMode;

  if (projectJarId != null) {
    link = link + '&projectJarId=' + projectJarId;
  }

  return link;
}

function updateLink(typeId, arg, options){
  if (!isPredefinedType(typeId) || isPredefinedReportType(typeId)){
    return openUpdateInstance(typeId, arg, options ? options : {});
  }
  return HOME_LINK + updatePath[typeId] + (arg ? '/'+ arg : '')
}

function deleteLink(typeId, arg, ownerId){
  if (!isPredefinedType(typeId) || isPredefinedReportType(typeId)) {
    if (app.builderMode) {
      return HOME_LINK + 'builder/deleteSelected'
    } else {
      return HOME_LINK + `entity${typeId}/deleteSelected`
    }
  } else {
    return HOME_LINK + resolver(deletePath, typeId, [ownerId]) + (arg ? '/'+ arg : '')
  }
}

function nullifyLink(typeId, arg, ownerId){
  return HOME_LINK + `entity${typeId}/nullifyGdprSelected`
}

function createLink(typeId, formViewId, options) {
  if (!isPredefinedType(typeId) || isPredefinedReportType(typeId)) {
    return openCreateInstance(typeId, formViewId, options)
  } else {
    return HOME_LINK + createPath[typeId] + (formViewId ? '/'+ formViewId : '')
  }
}

function presentationInfoForCardLink(typeId, formViewId, options) {
  return HOME_LINK + 'entity' + typeId + '/presentationinfoforcard?formViewId=' + formViewId
}

function dependenciesLink(typeId, arg) {
  if (!isPredefinedType(typeId) || isPredefinedReportType(typeId)) {
		if (app.builderMode) {
			return HOME_LINK + 'builder/dependencies'
		} else {
			return undefined // intended return, see confirm modal js
		}
  } else {
    return HOME_LINK + resolver(dependenciesPath, typeId, [arg])
  }
}

function typeIndexPageLink(typeId, options) {
  options = options || {};
	if (app.builderMode) {
		return HOME_LINK + 'entityType/instances' + '/' + typeId + (options.rowViewId ? '?rowViewId=' + options.rowViewId : '');
	} else {
		return HOME_LINK + 'entity' + typeId + (options.rowViewId ? '?rowViewId=' + options.rowViewId : '');
	}
}

function instanceUsageLink(itemId) {
	if (app.builderMode) {
		return HOME_LINK + 'builder/instanceUsages' + '/' + itemId ;
	} else {
		throw 'Invalid operation';
	}
}

function openUpdateInstance(typeId, instanceId, options) {
  var link = '';
  if (app.builderMode) {
    link += HOME_LINK + 'entityType/updateInstance' + '/' + typeId + '/' + instanceId;
  } else {
    link += HOME_LINK + 'entity' + typeId + '/update/' + instanceId;
  }
  let args = 0;
  _.each(options, (val, key) => {
    if (val) {
      link += args ? '&' : '?'
      link += key + '=' + val;
      ++args;
    }
  })
  return link;
}

function openCreateInstance(typeId, formViewId, options) {
	if (app.builderMode) {
		return HOME_LINK + 'entityType/createInstance' + '/' + typeId +  (formViewId ? '?formViewId=' + formViewId : '');
	} else {
		let link =  HOME_LINK + 'entity' + typeId + '/create';

		let addArg = (() => {
				let args = 0;
				return (key, val) => {
					link += args ? '&' : '?'
					link += key + '=' + val;
					++args;
				}
		})();

		if (formViewId)addArg('formViewId', formViewId);
		if (options)_.each(options, (val, key) => {
			if (val)addArg(key, val);
		})
		return link;
	}
}

function entityTypeDetailsLink(typeId, configurationId, usageReferer) {
	let configuration = app.urls.open(Constants.ID_TYPE_CONFIGURATION, configurationId)
	if (!typeId && usageReferer === UsageReferrer.SHARED_FIELDS_TABLE) {
		return configuration + '/entityField';
	}
	if (usageReferer === UsageReferrer.PERMISSION) {
		return configuration + '/permission/' + typeId;
	}
	if (usageReferer === UsageReferrer.ENTITY_TYPE) {
		return configuration + '/entityType/details/' + typeId;
	}
	if (usageReferer === UsageReferrer.ENTITY_ROUTE) {
		return configuration + '/configuration/update#routes';
	}
	if (usageReferer === UsageReferrer.API_ENTRY) {
		return configuration + '/apiEntry';
	}
	if (usageReferer === UsageReferrer.ENTITY_MENU) {
		return configuration + '/entityMenu/' + typeId;
	}
}

function usageDetailsLink(usageId, usageReferrer, typeId, configurationId, parentUsageKind) {
	let configuration = app.urls.open(Constants.ID_TYPE_CONFIGURATION, configurationId)
	if (usageReferrer === UsageReferrer.CONFIGURATION) {
		return configuration + '/configuration/update';
	}
	if (usageReferrer === UsageReferrer.ENTITY_VIEW) {
		return configuration + '/entityView/edit/' + typeId + '/' + usageId;
	}
	if (usageReferrer === UsageReferrer.ENTITY_BLOCK) {
		return configuration + '/entityBlock/construct/' + usageId;
	}
	if (usageReferrer === UsageReferrer.ENTITY_FIELD || usageReferrer === UsageReferrer.SHARED_FIELD) {
		return app.urls.entityTypeDetails(typeId, configurationId, parentUsageKind);
	}
	if (usageReferrer === UsageReferrer.ENTITY_MENU) {
		if (usageId) return configuration + '/entityMenu/' + usageId;
		return configuration + '/entityMenu';
	}
	if (usageReferrer === UsageReferrer.ENTITY_MENU_ITEM) {
		return configuration + '/entityMenu/' + typeId;
	}
	if (usageReferrer === UsageReferrer.PERMISSION) {
		return configuration + '/permission/' + usageId;
	}
	if (usageReferrer === UsageReferrer.ENTITY_ROUTE) {
		return configuration + '/configuration/update#routes';
	}
	if (usageReferrer === UsageReferrer.API_ENTRY) {
		return configuration + '/apiEntry';
	}
	if (usageReferrer === UsageReferrer.ENTITY_TYPE) {
		return configuration + '/entityType/details/' + usageId;
	}
}

function taskRethrowLink(taskId){
  return HOME_LINK + 'taskmanager/rethrow/' + taskId
}

function websocketTopicLink(confId){
  return '/topic/buildstatus/' + confId
}

function htmlByMenuIdLink(menuId){
  return HOME_LINK + 'entityMenu/htmlByMenuId/' + menuId
}

function menuBlockDependencyLink(blockId){
  return HOME_LINK + 'entityMenu/menuBlockDependency/' + blockId
}

function apiEntryByIdLink(entryId){
  return HOME_LINK + 'apiEntry/findApiEntry/' + entryId
}

function blockConstructLink(blockId){
  return HOME_LINK + 'entityBlock/construct/' + blockId
}

function getWorkspaceSourseLink(blockId){
  return HOME_LINK + 'entityBlock/getWorkspaceSourse/' + blockId
}

function builtCodeLink(blockId){
  return HOME_LINK + 'entityBlock/code/' + blockId
}

function flowByIdLink(flowId){
  return HOME_LINK + 'cjhelper/flow/' + flowId
}

function flowByUpidLink(upid){
  return HOME_LINK + 'cjhelper/flowByUpid/' + upid
}

function flowByUpidIfNeededLink(upid){
  return HOME_LINK + 'cjhelper/flowByUpidIfNeeded/' + upid
}

function createAndAddToRowViewLink(ownerId, viewId){
  return HOME_LINK + 'entityField/createAndAddToRowView/' + ownerId + (viewId ? ('/' + viewId) : '')
}

function openDynamicInstanceLink(id){
  return HOME_LINK + 'open/' + id
}

function hubLoginLink() {
  return '/' + app.currentLanguage + '/hub-login';
}

function resolver(array, type, args) {
    let formatted = array[type] || '';
    for (var i = 0; i < args.length; i++) {
      var regexp = new RegExp('\\{'+i+'\\}', 'gi');
      formatted = formatted.replace(regexp, args[i]);
    }
    return formatted;
}

function getJournalRecordsCount (documentId) {
	return HOME_LINK + 'documentTimeline/getJournalRecordsCount/' + documentId
}

function getJournalRecords (documentId, journalTypeId) {
	return HOME_LINK + 'documentTimeline/getJournalRecords/' + documentId + '/' + journalTypeId
}

function getJournalRecordsPage (documentId, journalTypeId) {
	return HOME_LINK + 'documentTimeline/getJournalRecordsPage/' + documentId + '/' + journalTypeId
}

function getApplicationVersionListLink(appId) {
  return HOME_LINK + 'getApplicationJars?appId=' + appId
}

function usePublish(state){
  return HOME_LINK + 'builder/switchpublish?m=' + state
}

function editView(viewId, ownerId) {
  return HOME_LINK + 'entityView/edit/' + ownerId + '/' + viewId;
}

function deleteView(viewId, ownerId) {
  return HOME_LINK + 'entityView/delete/' + ownerId + '/' + viewId;
}

function editViewProperties(viewId, ownerId) {
  return HOME_LINK + 'entityView/formProperties/' + viewId;
}

function createView(ownerId) {
  return HOME_LINK + 'entityView/create/' + ownerId;
}

function rowClickLink(typeId, fieldId, rowClickedId) {
  return HOME_LINK + 'entity' + typeId + '/rowclick/' + fieldId + '/' + rowClickedId
}

function createFieldFromPreset(ownerId, options) {
  let link = HOME_LINK + 'entityField/createFromPreset/' + ownerId;
  let args = 0;
  if (options.addToRowView) {
    link += '?addToRowView=true';
    args++;
  }
  if (options.view) {
    link += args ? '&' : '?';
    link += 'viewId=' + options.view.id;
    args++;
  }
  if (options.generateEnum) {
    link += args ? '&' : '?';
    link += 'generateEnum=true';
    args++;
  }
  if (options.isEmbedded) {
    link += args ? '&' : '?';
    link += 'isEmbedded=true';
  }
  return link;
}

function data(typeId, objectId, options){
  let urlService = new UrlServicePrototype()
  let viewId = options && options.viewId;
  let url;
  const params = urlService.getAllParameters();
  if (objectId) {
    url = app.builderMode
      ? HOME_LINK + 'metaObject/' + typeId + '/open/' + objectId
      : HOME_LINK + 'entity' + typeId + '/open' + (viewId ? ('/' + viewId) : '') + '/' + objectId;
  } else {
    url = app.builderMode
      ? HOME_LINK + 'metaObject/' + typeId + '/onCreate/'
      : HOME_LINK + 'entity' + typeId + '/onCreate/';
    params.formViewId = viewId;
    params.fillFunctionId = options && options.fillFunctionId;
    params.fillInstanceId = options && options.fillInstanceId;
  }
  const paramsStr = _.chain(params)
    .pick(_.identity)
    .map((v, k) => `${k}=${v}`)
    .join('&')
    .value();
  if (paramsStr) {
    url += '?' + paramsStr;
  }
  return url;
}

function presentationInfo(typeId, viewId, isIndex) {
  let url = app.builderMode
    ? HOME_LINK + 'entityType/presentationInfo/' + typeId
    : HOME_LINK + 'entity' + typeId + '/presentationInfo';
  url += "?v=" + app.info.buildNumber;
  if (viewId) {
    url += '&viewId=' + viewId;
  }
  if (isIndex) {
    url += '&isIndex=true'
  }
  return url;
}

function customEvent(typeId, blockId) {
  return HOME_LINK + 'entity' + typeId + '/invokeCustomEvent/' + blockId;
}

function change(typeId, fieldId, subFieldId, relativeOrder, userAction) {
  let subField = !subFieldId ? '' : 'subFieldId=' + subFieldId.toString() + '&';
  let relativeOrderParameter = relativeOrder === null ? '' : 'relativeOrder=' + relativeOrder.toString();
  return HOME_LINK + `entity${typeId}/change/${fieldId}/?userAction=${userAction}&${subField}${relativeOrderParameter}`;
}

function suggest(typeId, fieldId, subFieldId, relativeOrder) {
  let subField = !subFieldId ? '' : 'subFieldId=' + subFieldId.toString() + '&';
  let relativeOrderParameter = relativeOrder === null ? '' : 'relativeOrder=' + relativeOrder.toString();
  return HOME_LINK + `entity${typeId}/suggestValues/${fieldId}/?${subField}${relativeOrderParameter}`;
}

function formLoad(typeId, viewId) {
  return HOME_LINK + 'entity' + typeId + '/formLoad/' + viewId;
}

function history(typeId, objectId) {
  return HOME_LINK + 'entity' + typeId + '/getHistory/' + objectId;
}

function dashboardAliases(builderId) {
  return "https://apps.codejig.com/" + app.currentLanguage + "/aliases?builder=" + builderId
}

function openEntitySpreadsheet(typeId, viewId) {
  return HOME_LINK + 'entity' + typeId + '/getSpreadsheet' + (viewId ? '?viewId=' + viewId : '')
}

function openEntityXml(typeId, viewId, skipValidation) {
  return HOME_LINK + 'entity' + typeId + '/getXml' + (viewId ? '?viewId=' + viewId : '') + (skipValidation ? (viewId ? '&' : '?') + 'skipValidation=true' : '')
}

function openEntityDoc(typeId, viewId, skipValidation) {
  return HOME_LINK + 'entity' + typeId + '/getDoc' + (viewId ? '?viewId=' + viewId : '') + (skipValidation ? (viewId ? '&' : '?') + 'skipValidation=true' : '')
}

function openEntityPdf(typeId, viewId, skipValidation) {
  return HOME_LINK + 'entity' + typeId + '/getPdf' + (viewId ? '?viewId=' + viewId : '') + (skipValidation ? (viewId ? '&' : '?') + 'skipValidation=true' : '')
}

function buildStringView(typeId, viewId, languageId) {
  return HOME_LINK + 'entity' + typeId + '/buildStringView' + (viewId ? '?viewId=' + viewId : '') + (languageId ? (viewId ? '&' : '?') + 'languageId='+languageId : '')
}
function buildStringViews(typeId, viewId, languageId) {
  return HOME_LINK + 'entity' + typeId + '/buildStringViews' + (viewId ? '?viewId=' + viewId : '') + (languageId ? (viewId ? '&' : '?') + 'languageId='+languageId : '')
}

function tasksByTarget(instanceId) {
  return HOME_LINK + 'entityTask/findByTarget/' + instanceId
}

function find(typeId, instanceId) {
  return HOME_LINK + 'entity' + typeId + '/find/' + instanceId + '/';
}

function buildReport(typeId) {
  return HOME_LINK + 'entity' + typeId + '/buildReport';
}

function runTestBlock(blockId) {
  return HOME_LINK + 'tests/' + blockId + '/run';
}

function reorder(entity) {
  return HOME_LINK + entity + '/reorder'
}

function checkPermissionLink(typeId){
  return  HOME_LINK + 'entity' + typeId + '/checkPermission'
}

function buildLink(devMode) {
	let url =  HOME_LINK + 'build';
	if (devMode) {
		url += '?devMode=true';
	}
	return url;
}

function publishLink(appId, devMode, skipSystemSeed) {
	let url =  HOME_LINK + 'publish' + '?appId=' + appId
	if (devMode) {
		url += '&devMode=true'
	}
	if (skipSystemSeed) {
		url += '&skipSystemSeed=true';
	}
	return url
}

function exportReportLink(typeId) {
  return HOME_LINK + 'entity' + typeId + '/exportReport';
}

function addView(typeId) {
  return HOME_LINK + 'entityView/create/' + typeId;
}

function addBlock(typeId) {
  return HOME_LINK + 'entityBlock/create/' + typeId;
}

function addFieldFunction(typeId) {
  return HOME_LINK + 'entityBlock/createFillFunction/' + typeId;
}

function addField(typeId) {
  return HOME_LINK + 'entityField/create/' + typeId;
}

function dataTypesPage(params) {
  let paramsStr = ''
  params.forEach((p) => {
    if (paramsStr.length) {
      paramsStr += `&${p}`
    } else {
      paramsStr += `?${p}`
    }
  })
  return HOME_LINK + 'entityType' + paramsStr
}

function getView(id) {
  return HOME_LINK + 'entityView/' + id
}

function partOfEditViewUrl(typeId) {
  return openPath[Constants.ID_TYPE_VIEW] + '/' + typeId
}

function getTitle(viewId, instanceId, typeId) {
  return HOME_LINK + (app.builderMode ? 'entityType' : 'entity') + '/getTitle/' + instanceId + '/' + viewId + '/' + typeId
}

function updatePassword(userId) {
	return HOME_LINK + 'appUserAccount/updatePassword/' + userId;
}

function updateUserAccount(userId) {
	return HOME_LINK + 'appUserAccount/updateUserAccount/' + userId;
}

function deleteUserAccount(userId) {
	return HOME_LINK + 'appUserAccount/deleteUserAccount/' + userId;
}

function refreshLdapGroups(userId) {
	return HOME_LINK + 'appUserAccount/refreshLdapGroups/' + userId;
}

function updateDataSource(dsId) {
  return HOME_LINK + 'dataSources/update/' + dsId
}
function deleteDataSource(dsId) {
  return HOME_LINK + 'dataSources/delete/' + dsId
}
function dependenciesDataSource(dsId) {
  return HOME_LINK + 'dataSources/dependencies/' + dsId
}
function testConnectionDataSource(dsId) {
  return HOME_LINK + 'dataSources/testConnection/' + dsId
}
function importDataSource(dsId, schemaImportDataCreatedAt) {
  return HOME_LINK + 'dataSources/import/' + dsId + "?schemaImportDataCreatedAt=" + schemaImportDataCreatedAt
}

function changeLogLink(start, num) {
  return HOME_LINK + 'builder' + '/getChangeLog/' + start + '/' + (num || 50)
}

export let  urls = {
  update: updateLink,
  delete: deleteLink,
  nullify: nullifyLink,
  create: createLink,
  deploy: deployLink,
  build: buildLink,
  getAllFields: (timestamp) => {return HOME_LINK + 'metadata/fields?v=' + timestamp},
  getAllTypes: (timestamp) => {return HOME_LINK + 'metadata/types?v=' + timestamp},
  getAllViews: (timestamp) => {return HOME_LINK + 'metadata/views?v=' + timestamp},
  getAllBlocks: (timestamp) => {return HOME_LINK + 'metadata/blocks?v=' + timestamp},
  cloneFormView: HOME_LINK + 'entityView/cloneFormView/',
  changePassword: HOME_LINK + 'changePassword',
  usePublish: usePublish,
  publish: publishLink,
  updateFieldName: HOME_LINK + 'entityField/updatename/',
  getApps: HOME_LINK + 'getApps',
  getCategories: HOME_LINK + 'entityCategory',
  import: HOME_LINK + 'import',
  export: HOME_LINK + 'export',
  allTypeReferences: HOME_LINK + 'entityType/allDependencies',
  generators: HOME_LINK + 'entityGenerator',
  formatters: HOME_LINK + 'primitiveFormatter',
  uploadFile: HOME_LINK + 'internalFile/',
  serverStatus: HOME_LINK + 'serverStatus',
  findResource: HOME_LINK + 'stringResources/find',
  findResourceByKey: HOME_LINK + 'stringResources/findByKey',
  findManyStringViews: HOME_LINK + 'findManyStringViews',
  systemInstancesForDeploy: HOME_LINK + 'systemInstancesForDeploy',
  createWebPage: HOME_LINK + 'entityType/createDirectoryWithWebPage',
  entity: HOME_LINK + 'entity',
  indexTemplate: HOME_LINK + 'entityView/getIndexView?v=' + app.info.buildNumber,
  getApplicationVersionList: getApplicationVersionListLink,
  getApplicationVersionListForMultiple: HOME_LINK + 'getApplicationJarsForMultiple',
	openDynamicInstance: openDynamicInstanceLink,
  updateDataSource: updateDataSource,
	getJournalRecordsCount,
	getJournalRecords,
	getJournalRecordsPage,
  createFieldFromPreset,
  createAndAddToRowViewLink,
  dashboardAliases,
  openEntitySpreadsheet,
  openEntityXml,
  openEntityDoc,
  openEntityPdf,
  tasksByTarget,
  find,
  buildReport,
  findManyStringViewsForReport: (typeId) => {
	return HOME_LINK + 'entity' + typeId + '/findManyStringViewsForReport' 
  },
  task: {
    cancel: HOME_LINK + 'taskmanager/cancel',
    rethrow: taskRethrowLink
  },
  websocket: {
    taskmanager: "/taskmanager",
    topic: websocketTopicLink
  },
  exportToXLS: HOME_LINK + 'export/',
  importFromXLS: HOME_LINK + 'import/',
  blockConstruct: blockConstructLink,
  getWorkspaceSourse: getWorkspaceSourseLink,
  builtCode: builtCodeLink,
  htmlByMenuId: htmlByMenuIdLink,
  menuBlockDependency: menuBlockDependencyLink,
  apiEntryById: apiEntryByIdLink,
  entityTypeDetails: entityTypeDetailsLink,
  usageDetails: usageDetailsLink,
  dependencies: dependenciesLink,
  useTemplate: useTemplate,
  open: openLink,
  hubLogin: hubLoginLink,
  indexPage: typeIndexPageLink,
  instanceUsages: instanceUsageLink,
  editView: editView,
  createView: createView,
  data: data,
  presentationInfo,
  invoke: {
    customEvent,
    change,
    formLoad,
    rowClick: rowClickLink,
    suggest
  },
  history,
  changeLog: changeLogLink,
  editViewProperties: editViewProperties,
  deleteView: deleteView,
  runTestBlock,
  flowById: flowByIdLink,
  flowByUpid: flowByUpidLink,
  flowByUpidIfNeeded: flowByUpidIfNeededLink,
  addFlowToUserAccount: HOME_LINK + "cjhelper/addFlowIdToUserAccount",
  clearFlowIds: HOME_LINK + "cjhelper/clearFlowIds",
  reorder: reorder,
  checkPermission: checkPermissionLink,
  exportReport: exportReportLink,
  downloadApp: downloadLink,
  getAllData: HOME_LINK + 'entityType/getAllData',
  getBlocks: HOME_LINK + 'entityBlock/getBlocks',
  getViews: HOME_LINK + 'entityView/getViews',
  getTypes: HOME_LINK + 'entityType/getTypes',
  getFields: HOME_LINK + 'entityField/getFields',
  getAllTypesAndCategories: HOME_LINK + 'entityType/getAllTypesAndCategories',
  addView: addView,
  addBlock: addBlock,
  addFieldFunction: addFieldFunction,
  addField: addField,
  updateRoute: HOME_LINK + 'configuration/routeUpdate',
  openRoutesInAdvancedMode: HOME_LINK + 'configuration/update?RoutesAdvancedMode#routes',
  recentlyEdited: HOME_LINK + 'recentlyEdited/',
  getMLS: HOME_LINK + 'multilingualString/getMLS',
  translateMLS: HOME_LINK + 'multilingualString/translateMLS',
  dataTypes: dataTypesPage,
  buildStringView: buildStringView,
  buildStringViews: buildStringViews,
  forceFullCompile: HOME_LINK + 'forceFullCompile',
  getView: getView,
  partOfEditViewUrl: partOfEditViewUrl,
  startTutorial: HOME_LINK + 'tutorials/start',
  nextStep: HOME_LINK + 'tutorials/next',
  getTitle: getTitle,
  replaceRoute: HOME_LINK + 'merge/replaceRoute',
	addEvent: HOME_LINK + 'configuration/addEvent',
	addNewUser: HOME_LINK + 'appUserAccount/create',
	updatePassword: updatePassword,
	updateUserAccount: updateUserAccount,
	deleteUserAccount: deleteUserAccount,
	refreshLdapGroups: refreshLdapGroups,
	createMenuBlock: HOME_LINK + 'entityBlock/create/entityMenu',
  createGroupFilter: HOME_LINK + 'groupFilter/create',
  createApplicationStart: HOME_LINK + 'eventWrapper/create',
  deleteDataSource: deleteDataSource,
  dependenciesDataSource: dependenciesDataSource,
  getPrimitiveFormatters: HOME_LINK + 'getPrimitiveFormatters/',
  testConnectionDataSource: testConnectionDataSource,
  generateConnectionIdentifier: HOME_LINK + 'dataSources/generateIdentifier',
  getStringViews: HOME_LINK + 'getStringViews',
  importDataSource: importDataSource,
  notifications: {
    subscribe: HOME_LINK + 'pushNotifications/subscribe',
    unsubscribe: HOME_LINK + 'pushNotifications/unsubscribe',
    isSubscribed: HOME_LINK + 'pushNotifications/isSubscribed',
    publicKey: HOME_LINK + 'pushNotifications/publicKey'
  },
  presentationInfoForCard: presentationInfoForCardLink
}
