import MetaObjectState from '../enums/metaObjectState';
import StringKind from '../enums/stringKind';
import PermissionFilterKind from '../enums/permissionFilterKind';
import DocumentRegisterState from '../../common/enums/documentRegisterState';
import * as blockFormatter from './blockFormatter';
import Formatter from './formatter';
import ModelFactory from '../models/modelFactory';
import entityManager from './entityManager.js';
import BlockKind from '../enums/blockKind';
import BlockType from '../enums/blockType';
import BlockTrigger from '../enums/blockTrigger';
import FieldKind from '../enums/fieldKind';
import SharedViewModifier from '../enums/sharedViewModifier.js';
import MultilingualString from '../models/multilingualString';
import utils from './utils';
import Instant from '../../time/models/instant';
import Duration from '../../time/models/duration';
import type Block from '../common/models/block';
import { icons } from './icons';
import PrimitiveEntityType from '../enums/primitiveEntityType';
import Constants from '../models/constants';
import States from '../enums/states';
import { translate } from '../../common/service/stringResourceService'

import { NANOS_PER_MILLI, MILLIS_PER_SECOND } from '../../time/constants';

var updateButton = function (data, modalMode) {
	const options = {
		class: 'btn btn-link btn-xs showOnHover update'
	};
	if (data) {
		if (modalMode) {
			options['data-id'] = data
		} else {
			options.href = data
		}
	}
	return $('<a>', options)
	.append($('<span>', {
		class: 'glyphicon glyphicon-pencil'
	})).prop('outerHTML');
};

var deleteButton = function (id) {
	return $('<button>', {
		'data-id': id,
		class: 'btn btn-link btn-xs showOnHover delete'
	}).append($('<span>', {
		class: 'glyphicon glyphicon-trash'
	})).prop('outerHTML');
};

var createDisableButton = function (id) {
	return $('<button>', {
		'data-id': id,
		class: 'disable-btn btn btn-link'
	}).append($('<span>', {
		class: 'glyphicon glyphicon-remove'
	})).prop('outerHTML');
};

var showBlockUsagesButton = function (id) {
	return $('<button>', {
		'data-blockId': id,
		'data-target': '#BlockUsageModal',
		class: 'btn btn-link btn-xs showOnHover'
	}).append($('<span>', {
		class: 'glyphicon glyphicon-link'
	})).prop('outerHTML');
};

var showFieldUsagesButton = function (id) {
	return $('<button>', {
		'data-fieldId': id,
		'data-target': '#FieldUsageModal',
		class: 'btn btn-link btn-xs'
	}).append($('<span>Show usages', {
		class: 'glyphicon glyphicon-link'
	})).prop('outerHTML');
};

var buildFieldName = function (name, field) {
	var value = formattersAndStyles.multilingualStringFormatter(name);
	let createLabel = (text) => $('<span class="label table-label label-default"/>').html(text)[0].outerHTML;
	let result = `<li class="dropdown body-container put-down">
			<span  class="dropdown-toggle" data-toggle="dropdown">
				<span class="cursor-pointer">${value}</span>
				<span class="fa fa-caret-down"></span>
			</span>
			<ul class="dropdown-menu">
				${blockFormatter.getFieldTooltip(field)}
			</ul>`;
	if (field.isGdprSensitive) {
		result += ' ' + createLabel('gdpr')
	}
	if(field.isShared) {
		result += ' ' + createLabel(app.getResource('shared'))
	}
	if (field.isTransient) {
		result += ' ' + createLabel(app.getResource('transient'));
	}
	if (field.isSystem) {
		result += ' ' + createLabel(app.getResource('system'));
	}
	if (field.isReadOnly) {
		result += ' ' + createLabel(app.getResource('read.only'));
	}
	if (field.fieldKind == FieldKind.COLLECTION) {
		result += ' ' + createLabel(app.getResource('collection'));
	}
	if (field.fieldKind == FieldKind.DYNAMIC) {
		result += ' ' + createLabel(app.getResource('dynamic'));
	}
	if (field.isVirtual) {
		result += ' ' + createLabel(app.getResource('virtual'));
	}
	if (field.isHidden) {
		result += ' ' + createLabel(app.getResource('code.only'));
	}
	if (field.isDataSourceKey) {
		result += ' ' + createLabel(app.getResource('primary.key'))
	}
	return result;
};

var buildTypeDetailsUrl = function (id, name) {
    return buildTypeDetailsUrlWithBadges(id, name, []);
}

var buildTypeDetailsUrlWithBadges = function (id, name, badges) {
	let typeDetails = $('<span>');

	if (Constants.isPredefined(id)){
		let span = $('<span>').text(name).attr('title', app.getResource('built.in.data.type'));
		let badge = $('<span>').addClass('label table-label label-default').text(app.getResource('system'));
		typeDetails.append(span);
		typeDetails.append(badge);
	}else{
		let typeA = $('<a>', {
			href: app.urls.open(Constants.ID_TYPE_TYPE, id),
			title: name
		}).text(name);

		typeDetails.append(typeA);
	}

	for (const bd of badges) {
		let badge = $('<span>').addClass('label table-label label-default').text(bd);
		typeDetails.append(badge);
	}

	var type = app && app.data && app.data.types && app.data.types.find(item=>item.id == id);
	if(type && type.primitiveEntityType == PrimitiveEntityType.STRING && !type.primitiveTypeProperties.stringHasTranslations && type.primitiveTypeProperties.stringKind != StringKind.HTML) {
		let badge = $('<span>').addClass('label table-label label-default').text('Deprecated');
		typeDetails.append(badge);
	}

	return typeDetails.prop('outerHTML');
};

var buildViewLinkUrl = function (id, name) {
	let view = $('<span>');

	let viewLink = $('<a>', {
		href: app.urls.open(Constants.ID_TYPE_VIEW, id),
		title: name
	}).text(name);

	view.append(viewLink);

	return view.prop('outerHTML');
};

var buildThemeUpdateUrl = function (id, name) {

	return `<a id="${id}" href="${app.urls.open(Constants.ID_TYPE_CSS_THEME, id)}">${name}</a>
		<li class="dropdown body-container put-down">
			<span  class="dropdown-toggle" data-toggle="dropdown">
				<span class="cursor-pointer"></span>
				<span class="fa fa-caret-down"></span>
			</span>
			<ul class="dropdown-menu">
				<li>
					<a href="#" data-id="${id}" class="applyTheme">
						<i class="material-icons notranslate fa defaultAction	">eject</i>
					 	<span>${app.getResource('make.default')}</span>
					 </a>
				</li>
				<li>
					<a href="#" data-id="${id}" class="deleteTheme">
						<i class="material-icons notranslate fa defaultAction">delete</i>
						<span>${app.getResource('delete')}</span>
					 </a>
				</li>
			</ul>
			${app.defaultThemeId === id ? '<a class="defaultTheme"><span class="label table-label default label-default">'+ app.getResource('default') + '</span></a>' : ''}`

};

var buildTypeIndexUrl = function (type) {
	let name = (new MultilingualString(type.name)).getCurrentValue();
	if (type.isPredefined){
		return $('<span>').text(name).prop('outerHTML');
	} else {
		return $('<span>', {
			class: 'btn btn-link ref',
			'data-kind': 'new.cj.tab',
			'data-index': true,
			'data-type': type.id,
			style: 'cursor:pointer;'
		}).text(name).prop('outerHTML');
	}
};

var applyButton = function (value, row, index) {
	var div = $('<div>');
	if (row.metaObjectState && row.metaObjectState !== 'ACTIVE' ||
			row.metaObject && row.metaObject.metaObjectState !== 'ACTIVE') {
		return '';
	}
	if (row.documentRegister.state != DocumentRegisterState.POSTED) {
		div.append($('<button>', {
			title: translate('button-before-posting-document'),
			id: row.id,
			class: 'btn btn-success btn-xs apply-in-collection'
		}).append($('<span>').attr({
			class: 'glyphicon glyphicon-hand-up'
		})));
	} else {
		div.append(showRegistersButton(value, row, index));
	}
	return div.prop('outerHTML');
};

var showRegistersButton = function (value, row, index) {
	return $('<button>', {
		title: translate('button-after-posting-document'),
		'data-document-id': row.id,
		'data-type-id': row.entityTypeId,
		class: 'show-registers-in-collection btn btn-primary btn-xs'
	}).append($('<span>', {
		class: 'glyphicon glyphicon-th-list'
	})).prop('outerHTML');
};

var dropdownCounter = 0;
var fillDropdown = function (value, row, index, fillFunctions) {
	var div = $('<div/>', {
		class: 'dropdown body-container put-down'
	});
	var buttonId = 'dropdownMenu' + dropdownCounter;
	var button = $('<button/>', {
		id: buttonId,
		class: 'btn btn-default btn-xs dropdown-toggle',
		type: 'button',
		'data-toggle': 'dropdown',
		'aria-haspopup': true
	}).append($('<span/>', {
		class: 'glyphicon glyphicon-plus'
	}));
	div.append(button);
	var list = $('<ul/>', {
		class: 'dropdown-menu limited-dropdown',
		'aria-labelledby': buttonId
	});
	_.each(fillFunctions || app.fillFunctions, function (fillFunction) {
		if(fillFunction.owner.id == row.entityTypeId) {
			var $fillFunction = $('<button class="btn-link ref fill-function">').attr('fillfunctionid', fillFunction.id)
				.attr('fillresulttypeid', fillFunction.fillResultType.id)
				.attr('fillinstanceid', row.id)
				.attr('target','_blank')
				.html(`<span>${fillFunction.name}</span>`)
			list.append($('<li/>').append($fillFunction));
		}
	});
	div.append(list);
	++dropdownCounter;
	return div.prop('outerHTML');
};

var makeDefaultButton = function (row) {
	if (row.viewModifier == SharedViewModifier.PUBLIC) {
		return $('<a>').attr({
			'data-id': row.id,
			class: 'btn btn-sm btn-primary makeDefault showOnHover'
		}).append($('<span>').attr({
			class: 'glyphicon glyphicon-triangle-left'
		}));
	}
};

var buildIndexFields = function (fields) {
	var result = '';
	for (var i = 0; i < fields.length; i++) {
		result += new MultilingualString(app.fields.get(fields[i]).name()).toHTML();
		if (i != fields.length - 1) {
			result += ', ';
		}
	}
	return result;
};

function getDivider(): string {
	return '<li class="divider"></li>';
}

function getIcon(options): string {
	let classes = (options.classes && `class="${options.classes}"`) || '';
	let data = (options.data &&
		_.map(Object.keys(options.data), key => {
				return `data-${key}="${options.data[key]}"`;
			}).join(' ')) || '';
	let iconClasses = options.iconClasses || '';
	let icon = options.icon || '';
	let text = options.text || '';
	let href = options.href ? `href="${options.href}"` : '';
	let iconData = ((iconClasses || icon) && `<i class="${iconClasses}">${icon}</i>`) || '';
	return `<a ${href} ${classes} ${data}>${iconData}<span>${text}</span></a>`;
}

function getAction(options: Object): string {
	return `<li>${getIcon(options)}</li>`
}

function getEditAction(object: Object, editOptions: Object): string {
	let options: any = _.clone(icons['edit']) || {};
	_.extend(options, editOptions || {});
	options.data = {
		id: object.id
	};
	options.text = options.text || app.getResource('edit');
	return getAction(options);
}

function getRemoveAction(object: Object, removeOptions: Object): string {
	let options: any = _.clone(icons['remove']) || {};
	_.extend(options, removeOptions);
	options.text = app.getResource('delete');
	options.data = {
		id: object.id
	};
	return getAction(options);
}

function getHistoryAction(object: Object, historyOptions: Object): string {
	let options: any = _.clone(icons['history']) || {};
	_.extend(options, historyOptions);
	options.text = app.getResource('show.history');
	options.data = {
		id: object.id
	};
	return getAction(options);
}

function getMakeDefaultAction(object: Object, opts: Object): string {
	let options: any = _.clone(icons['makeDefault']) || {};
	_.extend(options, opts);
	options.text = app.getResource('make.default');
	options.data = {
		id: object.id
	};
	return getAction(options);
}

export function getCopyAction(copyOptions: Object): string {
	let options = _.clone(icons['copy']) || {};
	_.extend(options, copyOptions);
	options.classes = (options.classes || '') + ' copy-to-clipboard';
	return getAction(options);
}

function getDropdownWithActions(content: string): string {
	return `<li class="dropdown body-container">
			<a class="dropdown-toggle" data-toggle="dropdown" data-placement="left">
				<i class="material-icons notranslate expand-without-margin">more_horiz</i>
			</a>
			<ul class="dropdown-menu eventsDropdown">
				${content}
			</ul>`;
};

function removeButtonAction(item: any): string {
	return `<li>
							<a class="remove" data-id="${item.id}">
								<span class="fa fa-trash-o defaultAction"></span>
								${app.getResource('delete')}
							</a>
					</li>`;
};

function htmlString(value) {
	if (!value) {
		return '';
	}
	let currentValue = MultilingualString.getCurrentValue(value);
	if (!currentValue || currentValue.length == 0) {
		return '';
	}
	let text = utils.htmlToText(currentValue)
			.split(/ |\n|\t/).filter(function (e) {
				return e.trim() != '';
			}).join(' ');
	if (text.length > app.maxHtmlLength) {
		text = text.substr(0, app.maxHtmlLength) + '...';
	}
	return text;
}

const disableAdminTooltip = _.once(() => app.getResource('disable.admin.user.tooltip'))

var formattersAndStyles = {

	name: function (value, row, index) {
		if (value) {
			var result = formattersAndStyles.multilingualStringFormatter(value, row, index);
			if (row.primitiveEntityType != null) {
				var typeName = row.primitiveEntityType;
				if (typeName == 'DECIMAL') {
					// fix primitiveTypeProperties
					typeName += ' ' + row.primitiveTypeProperties.decimalScale;
				}
				// fix primitiveTypeProperties
				if (row.primitiveTypeProperties.stringHasTranslations) {
					typeName = 'MULTILINGUAL ' + typeName;
				}
				var h6 = $('<h6>').attr({
					class: 'show-inline'
				}).append($('<span>').attr({
					class: 'label label-default',
					style: 'vertical-align: middle'
				}).text(typeName));

				result = result + h6.appendTo('<div>').parent().html();
			}
			return result;
		} else {
			return null;
		}
	},

	stringResourceIdFormatter: function (value, row, index) {
		var result = value;
		if (row.isSystem) {
			var h6 = $('<h6>').attr({
				class: 'show-inline'
			}).append($('<span>').attr({
				class: 'label label-default',
				style: 'vertical-align: middle'
			}).text('SYSTEM'));
			result = result + h6.appendTo('<div>').parent().html();
		}
		return result;
	},

	multilingualStringFormatter: function (value, row, index) {
		var ms = value ||	row && row.value && row.value[this.field];
		return MultilingualString.toHTML(ms);
	},

	apiUrlPath: function (value, row, index) {
		return `<label style="margin: 0 4px 0 0; font-weight: initial; color: #808080">/api/</label>${value}`
	},

	htmlStringFormatter: function(value, row, index) {
		return htmlString(value);
	},

	htmlStringFormatterWithLink: function (value, row, index) {
	  let text = htmlString(value);
		var popover = $('<a href="#" data-toggle="popover"/>');
	  popover.attr("data-trigger", "hover");
	  popover.attr('data-html', 'true');
		popover.css('display', 'block')
		popover.attr("data-placement", "auto")
		let popoverText = MultilingualString.getCurrentValue(value);
	  popover.attr('data-content', popoverText);
	  popover.addClass('popover-tooltip');
		popover.text(text);
		return popover[0].outerHTML;
	},

	enumValue: function (value, row, index) {
		return value;
	},

	indexAction: function (value, row, index) {
		return updateButton(app.urls.indexUpdate + '/' + row.id, false);
	},

	indexFields: function (value, row, index) {
		return buildIndexFields(value);
	},

	fieldName: function (value, row, index) {
		return buildFieldName(value, row);
	},

	typeActionsContent: function (value, row, index) {
		const content = `${getEditAction(row, { classes: 'editTypeName' })}
					${getEditAction(row, { classes: 'editTypeSystemName',
																text: app.getResource('edit.system.name') })}
					${getDivider()}
					${getCopyAction({
						text: `${app.getResource('copy.id')}: ${row.id}`,
						data: {copy: row.id}
					})}
					${row.systemName ? getCopyAction({
						text: `${app.getResource('copy.system.name')}: ${row.systemName}`,
						data: {copy: row.systemName},
						classes: 'copyTypeSystemName'
					}) : ''}
					${getDivider()}
					${getEditAction(row, {classes: 'get-type-usage', icon: 'timeline', text: translate("get.usages") })}
					${getDivider()}
					${getRemoveAction(row, { classes: 'removeType' })}
					${getDivider()}
					${getHistoryAction(row, { classes: 'showCangeLog' })}`
		return content;
	},

	extendedTypeActionsContent: function(type) {
		return formattersAndStyles.typeActionsContent(null, type) +
		`${getDivider()}
			${blockFormatter.getUsagesAction(type, 'modules', app.getResource('modules.referencing.type'))}
			${blockFormatter.getUsagesAction(type, 'modules', app.getResource('modules.referenced.by.type'))}`
	},

	typeActions: function (value, row, index) {
		return getDropdownWithActions(formattersAndStyles.typeActionsContent(value, row, index));
	},


	sharedFieldActions(value, row, index) {
		let content;
		if (row.isSystem) {
			content = `${getCopyAction({
				text: `${app.getResource('copy.id')}: ${row.id}`,
				data: {copy: row.id}
			})}`;
		} else {
			content = `
						${getEditAction(row, { classes: 'edit' })}
						${getEditAction(row, {
							classes: 'editSystemName',
							text: `${app.getResource('edit.system.name')}`
						})}
						${getDivider()}
						${getCopyAction({
							text: `${app.getResource('copy.id')}: ${row.id}`,
							data: {copy: row.id}
						})}
						${row.systemName ? getCopyAction({
							text: `${app.getResource('copy.system.name')}: ${row.systemName}`,
							data: {copy: row.systemName}
						}) : ''}
						${row.columnName ? getCopyAction({
							text: `${"Copy DB name"}: ${row.columnName}`,
							data: {copy: row.columnName}
						}) : ''}
						${getDivider()}
						${getRemoveAction(row, { classes: 'removeField' })}`;
		}
		return getDropdownWithActions(content);
	},

	eventHandlerActions: function (value, row, index) {
		const content = `${getRemoveAction(row, { classes: 'removeHandler' })}`;
		return getDropdownWithActions(content);
	},

	apiEntryActions (value, row, index) {
		const content = `${getEditAction(row, { classes: 'editApiEntry' })}
						${getRemoveAction(row, { classes: 'removeEntry' })}`;
		return getDropdownWithActions(content);
	},

	dataSourceActions (value, row, index) {
		const content = `${getEditAction(row, { classes: 'editDataSource' })}
										${getDivider()}
										${ app.builderMode ? getCopyAction({ text: `${app.getResource('copy.id')}: ${row.id}`, data: {copy: row.id}}) : ''}
										${ app.builderMode ? formattersAndStyles.getUsagesAction(row) : ''}
										${ formattersAndStyles.getTestConnectionAction(row) }
										${ app.builderMode ? formattersAndStyles.getImportLink(row) : '' }
										${getDivider()}
										${ app.builderMode ? getRemoveAction(row, { classes: 'removeDataSource' }) : ''}`
		return getDropdownWithActions(content)
	},

	getUsagesAction (row) {
		let content = `${getEditAction(row, {
			classes: 'get-usages',
			icon: 'timeline',
			text: translate("get.usages") })}`;
		return content
	},

	getTestConnectionAction (row) {
		let content = `${getEditAction(row, {
			classes: 'testConnection',
			icon: 'import_export',
			text: translate('test.connection') })}`;
		return content
	},

	getImportLink (row) {
		let content = `${getEditAction(row, {
			icon: 'system_update_alt',
			text: translate('import'),
			href: app.urls.importDataSource(row.id)
		})}`
		return content
	},

	primitiveFormatterActions (value, row, idx) {
		const content = `
			${!row.isDefault ? getMakeDefaultAction(row, { classes: 'makeDefault'}) : ''}
			${getCopyAction({
				text: `${app.getResource('copy.id')}: ${row.id}`,
				data: {copy: row.id},
				iconClasses: 'material-icons notranslate material-icons-f16 fa'
			})}
			${!row.isPredefined ? getEditAction(row, {classes: 'editFormatter', iconClasses: 'fa material-icons notranslate material-icons-f16'}) : ''}
			${!row.isPredefined ? getRemoveAction(row, { classes: 'removeFormatter', iconClasses: 'fa material-icons notranslate material-icons-f16' }) : ''}
		`;
		return getDropdownWithActions(content);
	},

	categoryTooltip: function (category) {
		let content = `${getEditAction(category, { classes: 'editCategory' })}
									${getRemoveAction(category, { classes: 'removeCategory' })}`;
		return getDropdownWithActions(content);
	},

	moduleTooltip: function (module) {
		let content = `${getEditAction(module, { classes: 'editModule' })}
									${getRemoveAction(module, { classes: 'removeModule' })}
									${getCopyAction({
										text: translate('copy.access.mask') + ':' + module.accessMask,
										data: {copy: module.accessMask}
									})}`;
		return getDropdownWithActions(content);
	},

	customResourceTooltip: function (value, row, index) {
		let content = `${getEditAction(row, { classes: 'editResource' })}
									${getRemoveAction(row, { classes: 'removeResource' })}`;
		return getDropdownWithActions(content);
	},

	schedulerTooltip: function (value, row, index) {
		let content = `${getRemoveAction(row, { classes: 'remove' })}`;
		return getDropdownWithActions(content);
	},

	stringResourceTooltip: function (value, row, index) {
		let content = `${getEditAction(row, {
			classes: 'get-usages',
			icon: 'timeline',
			text: translate("get.usages") })}`;
		return getDropdownWithActions(content);
	},

	importTooltip: function (value, row, index) {
		let content = `${getRemoveAction(row, { classes: 'removeRow' })}`;
		return getDropdownWithActions(content);
	},

	fieldActions: function (value, row, index) {
		return updateButton(app.urls.fieldUpdate + '/' + row.id, false);
	},

	triggerAction: function (value, row, index) {
		return updateButton(app.urls.triggerUpdate + '/' + row.id, false);
	},

	documentTimelineActions: function (value, row, index) {
		return updateButton(app.urls.documentTimelineUpdate + '/' + row.id, false);
	},

	userRolesFormatter: function (value, row, index) {
		var result = '';
		_.each(value, function (role) {
			result += MultilingualString.getCurrentValue(role.name) + ' ';
		});
		return result;
	},

	userAccountFormatter: function (value, row, index) {
		return value &&
			(value.firstName && MultilingualString.getCurrentValue(value.firstName) ||
			value.lastName && MultilingualString.getCurrentValue(value.lastName) ?
			MultilingualString.getCurrentValue(value.firstName) + ' ' +
			MultilingualString.getCurrentValue(value.lastName) :
			value.userName);
	},

	configurationsFormatter: function (value, row, index) {
		let result = '';
		value.forEach(function (con, i) {
			result += MultilingualString.getCurrentValue(con.name) +
				(i !== value.length - 1 ? ', ' : '');
		});
		return result;
	},


	removeActionFormatter: function (value, row, index) {
		return $('<div>').append(
				deleteButton(row.id))
			.html();
	},

	emptyUpdate: function (value, row, index) {
		return $('<div>').append(
				updateButton(null, false))
			.html();
	},

	appUserRowStyle: function (row, index) {
		return {
			classes: 'ignore-user-focus'
		};
	},

	appUserToggleSwitchFormatter: function (value, row, index) {
		const isAdmin = _.find(row.userRoles, role => {
			return role.id === Constants.ID_USER_ROLE[Constants.USER_ROLE_ADMIN_NAME]
		}) != null;
		const adminTooltipAttrs = `title="${disableAdminTooltip()}" data-toggle="tooltip" data-placement="bottom"`;
		return `<div class="toggle-switch">
				<input data-uniqueid="${row.id}"
						id="${'cmn-toggle-' + row.id}"
						class="cmn-toggle cmn-toggle-round is-active"
						type="checkbox"
						${row.isActive ? 'checked' : ''}
						${isAdmin ? 'disabled="disabled"' : ''}>
				<label for="${'cmn-toggle-' + row.id}" tabindex="0"
						${isAdmin ? adminTooltipAttrs : ''}></label>
			</div>`;
	},

	appUserRefreshLdapRolesFormatter: function (value, row, index) {
		const options = {
			class: 'btn btn-link btn-xs showOnHover updateLdapRoles',
			'data-id': row.id
		};
		return $('<a>', options)
		.append($('<span>', {
			class: 'glyphicon glyphicon-refresh'
		})).prop('outerHTML');
	},

	languageActions: function (value, row, index) {
		var $div = $('<div>');
		$div.append(createDisableButton(row.languageId));
		return $div.html();
	},

	checkFormatter: function (value, row, index) {
		if (!app.configuration.isSystem && row.configuration.isSystem) {
			return {
				disabled: true
			};
		} else {
			return {
				checked: value
			};
		}
	},

	entityCheckFormatter: function (value, row, index) {
		if (row.metaObject && row.metaObject.metaObjectState !== MetaObjectState.ACTIVE) {
			return {
				disabled: true
			};
		} else {
			return {
				checked: value
			};
		}
	},

	entityGeneratorActions: function (value, row, index) {
		if (!app.configuration.isSystem && row.configuration.isSystem) {
			return '';
		}
		return updateButton(row.id, true);
	},

	sharedStyleActions: function (value, row, index) {
		if (!app.configuration.isSystem && row.configuration.isSystem) {
			return '';
		}
		return updateButton(row.id, true);
	},

	viewName: function (value, row, index) {
		return `<a href="${app.urls.entityView + '/' + row.id}" class="view-padding">${(new MultilingualString(row.name)).toHTML()}</a>` +
			(row.viewModifier == 'DEFAULT' ? `<a><span class="badge">${row.viewModifier.toString().charAt(0)}</span></a>` : '');
	},

	fieldUsages: function (value, row, index) {
		return showFieldUsagesButton(row.id);
	},

	fillFunctionTooltip: function (value, row, index) {
		return getDropdownWithActions(`${removeButtonAction(row)}`);
	},

	viewTooltip: function (value, row, index) {
		return blockFormatter.getDropdownWithCreateViewEvents(row);
	},

	fieldTooltip: function (value, row, index) {
		return blockFormatter.showFieldTooltip(row);
	},

	systemInstanceTooltip: function (value, row, index) {
		return blockFormatter.showSystemInstanceTooltip(row);
	},

	eventBlock: function (value, row, index) {
		return blockFormatter.showEventBlock(row, this.priority);
	},

	viewEventBlock: function (value, row, index) {
		return blockFormatter.showViewEventBlock(row, this.priority);
	},

	typeLink: function (value, row, index) {
		if (value) {
			return buildTypeDetailsUrl(value.id, (new MultilingualString(value.name)).getCurrentValue());
		} else {
			return null;
		}
	},

	typeLinkForApiFunctions: function (value, row, index) {
		if (value) {
			let badges = [];

			if (value.isCollection) {
			    badges.push(app.getResource('collection'));
			}

			return buildTypeDetailsUrlWithBadges(value.type.id, (new MultilingualString(value.type.name)).getCurrentValue(), badges);
		} else {
			return null;
		}
	},

	viewLinkForApiFunctions: function (value, row, index) {
		if (value) {
			return buildViewLinkUrl(value.id, (new MultilingualString(value.name)).getCurrentValue());
		} else {
			return null;
		}
	},

	typeIndexLink: function (value, row, index) {
		if (value) {
			return buildTypeIndexUrl(value);
		} else {
			return null;
		}
	},

	actionsFormatter: function (value, row, index) {
		return applyButton(value, row, index);
	},

	fillActionFormatter: function (value, row, index, fillFunctions) {
		return fillDropdown(value, row, index, fillFunctions);
	},

	highlight: function(text, subtext) {
		let pos = 0;
		let wrapper = (t) => {
			return `<span style="background-color: yellow">${t}</span>`;
		};
		return text.replace(new RegExp(subtext, 'gi'), wrapper);
	},

	stringViewSearchFormatter: function(value, row, index) {
		let highlighted = formattersAndStyles.highlight(value, app.searchView.table.likeString);
		return formattersAndStyles.getEntityLink(highlighted, row.objectType && row.objectType.id, row.metaObjectId);
	},

	getEntityLink(text, typeId, id, viewId) {
		return this._createLink(app.urls.update(typeId, id, {formViewId:viewId}), text);
	},

	_createLink(href, text) {
		const onlyText = _.escape(utils.htmlToText(text));
		return `<a class="entityLink"
			href="${href}"
			target="_blank" title="${onlyText}">${text}</a>`;
	},

	getDynamicEntityLink(text, id) {
		return this._createLink(app.urls.openDynamicInstance(id), text);
	},

	getLink: function(text, url) {
		const link = $('<a>').attr('href', url).html(text);
		return link[0].outerHTML;
	},

	css: function(value, row, index) {
		return value && value.replace(/(\r\n|\n|\r)/gm, ' ').replace(/\s+/g, ' ');;
	},

	formatter: function(value, row, index) {
		var result;
		var promise;
		value = value || row && row.value && row.value[this.field];
		if (value && value.id && this.entityTypeId) {
			if (this.viewId) {
				result = (entityManager.getStringView(this.viewId, value.id))
				if (!result){
					promise=entityManager.fetchStringView(this.viewId, value.id)
					result=utils.stringView(promise,this.viewId, value.id);
				}else{
					promise=Promise.resolve(result)
				}
			} else {
				result = (entityManager.getStringView(null, value.id))
				if (!result){
					promise=entityManager.fetchStringView(null, value.id)
					utils.stringView(promise,null, value.id);
				}else{
					promise=Promise.resolve(result)
				}
			}
			if (this.isLink !== false) {
				promise= promise.then((result)=>{
					return value.id && formattersAndStyles.
				 	getEntityLink(result, this.entityTypeId, value.id);
				})
				result = value.id && formattersAndStyles.
					getEntityLink(result, this.entityTypeId, value.id);
			}
		} else if (value && value.id && value.objectType) {
			result = (entityManager.getStringView(null, value.id))
			if (!result){
				promise=entityManager.fetchStringView(null, value.id)
				utils.stringView(promise,null, value.id);
			}else{
				promise=Promise.resolve(result)
			}
			if (this.isLink !== false) {
				 promise=promise.then((result)=>{return value.id && formattersAndStyles.
				 	getEntityLink(result, this.entityTypeId, value.id);})
				result = value.id && formattersAndStyles.
					getEntityLink(result, value.objectType.id, value.id);
			}
		} else {
			result = value;
			promise = Promise.resolve(value);
		}
		if (this.newTable){
			return promise
		}else{
			return result === null ? '' : result;
		}
	},

	rowStyle: function(row, index) {
		var classes = ['success', 'info', 'danger'];

		if (row.clientState === States.NEW) {
			return {
				classes: classes[0]
			};
		} else if (row.clientState === States.UPDATED) {
			return {
				classes: classes[1]
			};
		} else if (row.clientState === States.DELETED) {
			return {
				classes: classes[2]
			};
		} else {
			return {};
		}
	},

	checkBoxStyle: function(value, row, index) {
		if (row.parent) {
			return {
				disabled: true
			}
		}
		return value;
	},


	checkBoxForField: function(value, row, index) {
		if (row.isSystem || row.configuration.isSystem || app.requireReadOnlyMode) {
			return {
				disabled: true
			}
		}
		return value;
	},

	indexRowStyle: function(row, index) {
		if (row.metaObject && row.metaObject.metaObjectState === MetaObjectState.DELETED) {
			return {
				classes: 'deleted'
			};
		} else if (row.metaObject && row.metaObject.isReadOnly) {
			return {
				classes: 'readOnlyRow'
			};
		} else  {
			return {};
		}
	},

	blockRowStyle: function(row, index) {
		let classes = '';
		if (!row.correctlyTranslated) {
			classes += 'rowWithErrors';
		}
		return {
			classes: classes
		};
	},

	viewRowStyle: function(row, index) {
		let classes = 'table-row-with-border ';
		classes += 'treegrid-' + row.id;
		if (row.parentViewId) {
			classes += ' treegrid-parent-' + row.parentViewId;
		}
		return {
			classes: classes
		};
	},

	primitiveFormatter: function(options) {
		return (value, row, index) => {
			if(value !== undefined && value !== null) {
				return Formatter.formatToHTML(value, options);
			}
			return Formatter.formatToHTML(row.value && row.value[this.field], options);
		}
	},

	types: function(value, row, index) {
		var result = '';
		if (value) {
			_.each(value, function(type) {
				if (result !== '') {
					result += ', ';
				}
				result += (new MultilingualString(type.name)).getCurrentValue();
			});
		}
		return result;
	},

	view: function(value, row, index) {
		return value && (new MultilingualString(value.name)).getCurrentValue();
	},

	predicate: function(aa, bb) {
		if (aa === undefined || aa === null) {
				aa = '';
		}
		if (bb === undefined || bb === null) {
				bb = '';
		}

		if ($.isNumeric(aa) && $.isNumeric(bb)) {
				aa = parseFloat(aa);
				bb = parseFloat(bb);
				if (aa < bb) {
						return -1;
				} else if (aa > bb) {
						return 1;
				}
				return 0
		}

		if (aa === bb) {
			 return 0;
		}

		// If value is not a string, convert to string
		if (typeof aa !== 'string') {
				aa = aa.toString();
		}

		if (aa === '' || bb === ''){
			return aa.localeCompare(bb, 'en-US') * -1;
		}

		return aa.localeCompare(bb, 'en-US');
	},

	dateAndTimePredicate: function(first, second) {
		const index = $.inArray(this.options.sortName, this.fields);
		const column = this.options.columns[0][index];
		first = first && ModelFactory.getPrimitiveType(column.primitiveType).fromJSON(first);
		second = second && ModelFactory.getPrimitiveType(column.primitiveType).fromJSON(second);
		return first && second ? first.compareTo(second) :
			formattersAndStyles.predicate(first && 'value', second);
	},

	multilingualstringPredicate: function(first, second) {
		return formattersAndStyles.predicate(
			MultilingualString.getCurrentValue(first),
			MultilingualString.getCurrentValue(second));
	},

	multilingualstringPredicateLastName: function(first, second) {
		return formattersAndStyles.predicate(first.lastName.value, second.lastName.value);
	},

	stringPredicate: function(first, second) {
		const index = $.inArray(this.options.sortName, this.fields);
		const column = this.options.columns[0][index];

		return formattersAndStyles.predicate(
			first && entityManager.getStringView(column.viewId || null,  first.id),
			second && entityManager.getStringView(column.viewId || null, second.id));
	},

	formatDurationHHMMSS(value) {
		let durationHHMMSS = '';
		if (!value) return durationHHMMSS;

		let time = new Date(value.seconds * 1000 + value.nanos / 1000000);

		durationHHMMSS += (time.getUTCHours() < 10 ? '0' : '') + time.getUTCHours();
		durationHHMMSS += (time.getUTCMinutes() < 10 ? ':0' : ':') + time.getUTCMinutes();
		durationHHMMSS += (time.getUTCSeconds() < 10 ? ':0' : ':') + time.getUTCSeconds();
		let millis = time.getUTCMilliseconds();
		durationHHMMSS += ( millis < 100 ? (millis < 10 ? '.00' : '.0') : '.' )  + millis;

		return durationHHMMSS;
	},

	task: {

		formatDate(time){
			return !time ? '' : Formatter.formatWithPredefinedFormat(Instant.fromJSON(time), {
				primitiveType: PrimitiveEntityType.TIMESTAMP
			})
		},

		formatDuration(d: Duration){
			let millis = Math.round(d.getSeconds() * MILLIS_PER_SECOND + d.getNano() / NANOS_PER_MILLI);
			millis = Math.max(millis, 0)
			if (millis < 1000){
					return millis + ' ms.';
			} else if (millis < 60000) {
					return Math.floor(millis / 100) / 10 + ' sec.';
			} else {
					return Math.floor(millis / 1000 / 60) + ' min. ' + Math.floor(millis / 1000 % 60) + ' sec.';
			}
		},

		formatTaskDuration(value, task){
			if (!task.timeStarted || !task.timeDone){
					return '';
			}
			let d = Duration.between(Instant.fromJSON(task.timeStarted), Instant.fromJSON(task.timeDone));
			return formattersAndStyles.task.formatDuration(d);
		}

	},

	editBlockLink: function (value, row, index) {
		return `
		<a href="${app.urls.blockConstruct(value.id)}">
			<span>${app.getResource('edit.block')}</span>
		</a>`;
	},

	themeLink: function (value, row, index) {
		if (value) {
			return buildThemeUpdateUrl(value.id, (new MultilingualString(value.name)).getCurrentValue());
		} else {
			return null;
		}
	},

	treeNodeStyleForModule(id) {
		return 'color:' + (!!app.model.get('modules').get(id) ? "green" : "red")
	}
};

export default formattersAndStyles;
