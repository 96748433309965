/* @flow */

import BaseModel from '../../common/models/baseModel';
import AppUpdateMode from '../../common/enums/appUpdateMode';
import { translate } from '../../common/service/stringResourceService.js';
import utils from "../../common/components/utils";
import CheckboxInput from "../../common/components/checkboxInput";
import {renderDeployList} from "../utils";
import { buildHelpPopover } from '../../help/docsResource'
import ApplicationType from '../../common/enums/applicationType';

export default class DefaultApplicationModal extends Backbone.View {

	constructor() {
		super({
			events: {}
		});
	}

	initialize () {
		let $el = $('#choose-app-modal');
		if ($el.length == 0) {
			var el = document.createElement('div')
			el.id = 'choose-app-modal'
			el.classList.add('modal')
			el.classList.add('fade')
			document.body.appendChild(el)
			$el = $('#choose-app-modal')
		}
		$el.html(this.getHTML())
		this.setElement($el);
		this.publishBtn = this.$el.find('.okButton');
	}

	getHTML () {
		return `
				<div class="modal-dialog modal-md">
					<div class="modal-content">
						<div class="modal-header create-modal-header">
							<h4>
							<span id="headerText"></span>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
									<span aria-hidden="true">×</span>
							</button>
							</h4>
						</div>
						<div class="modal-body row">
							<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
								<label>${app.getResource('applications')}</label>
								<div id="application-select" class="application-select"></div>
							</div>
							<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
								<div style="margin: 30px 0px 0px 7px">
									<div id="skipSystemSeedDefault"></div>
									<span>
										${translate('skip.system.seed')}
										<a style="margin-left: 5px;" help-popover="skip-system-seed-help">
											<img class="help-image" src="/resources/images/question-mark.svg"/>
										</a>
									</span>
								</div>
							</div>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-default pull-left" data-action="cancel"
									data-dismiss="modal">${app.getResource('cancel')}
							</button>
							<button type="button" class="btn btn-danger pull-right okButton" data-action="create" disabled="true"
									data-dismiss="modal">${app.getResource('ok')}
							</button>
						</div>
					</div>
				</div>`;
	}

	show (options: Object) {
		let that = this;
		this.model = options.model || new BaseModel({
			applicationUpdateMode: AppUpdateMode.READ_ONLY,
			previousDefaultAppToPublish: null
		});

		this.headerResource = options.headerResource;
		this.isDev = options.isDev;

		this.$el.find('.modal-header h4 #headerText').html(options.headerResource);
		if (options.okResource) {
			this.publishBtn.text(translate(options.okResource));
		}

		this.publishBtn.off('click').on('click', e => {
			if (options.onSubmit) {
				options.onSubmit(that.model);
			}
			that.$el.modal('hide');
			e.stopPropagation();
		});

		const previousApp = JSON.parse(this.model.get('previousDefaultAppToPublish'));

		utils.getRequest(app.urls.getApps).then(applications => {
			this.applications = _.filter(applications, (app) => !ApplicationType.isRemote(app.applicationType));
			renderDeployList({
				$el: this.$el.find('#application-select'),
				applications: this.applications
			});
			this.models = new Backbone.Collection();
			let hadDefault = false;
			let hadSkipSystemSeed = false;
			this.$el.find('.selection-checkbox').each((idx, el) => {
				const appIdx = this.applications[idx];
				const wasDefault = previousApp ? previousApp.instanceId === appIdx.instanceId : false;
				const skipSystemSeed = (previousApp && previousApp.hasOwnProperty('skipSystemSeed')) ? (previousApp.instanceId === appIdx.instanceId && previousApp.skipSystemSeed) : false;
				hadDefault = hadDefault || wasDefault;
				hadSkipSystemSeed = hadSkipSystemSeed || skipSystemSeed;
				let model = new Backbone.Model({
					app: appIdx,
					isSelected: wasDefault,
					skipSystemSeed: skipSystemSeed
				});
				that.models.add(model);
				if (wasDefault) {
					this.model.set('app', model.get('app'));
				}
				new CheckboxInput({
					el: el,
					model: this.models.at(idx),
					modelAttr: 'isSelected'
				});
			});
			this.listenTo(this.models, 'change', this.onChange);

			if (hadDefault) {
				this.publishBtn.prop('disabled', false);
			}

			this.model.set('skipSystemSeed', hadSkipSystemSeed);
			new CheckboxInput({
				el: this.$el.find('#skipSystemSeedDefault')[0],
				model: this.model,
				modelAttr: 'skipSystemSeed'
			})
		});

		this.$el.find('[help-popover]').each(function (a, elem) {
			buildHelpPopover((elem))
		});

		this.$el.modal({backdrop: 'static'})
	}


	onChange(model) {
		if (model.get('isSelected')) {
			_.each(model.collection.models, (m) => {
				if (m.cid !== model.cid) {
					m.set('isSelected', false);
				}
			});
			this.model.set('app', model.get('app'));
			this.model.set('skipSystemSeed', model.get('skipSystemSeed'));
			this.publishBtn.prop('disabled', false);
		} else {
			this.model.set('skipSystemSeed', false);
			this.publishBtn.prop('disabled', true);
		}
	}
}