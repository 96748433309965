import PropertiesType from '../../../common/enums/propertyType.js';
import PropertiesKey from '../../../common/enums/propertyKey.js';
import {PropertyGroupKeys} from '../../../common/enums/propertyGroup.js';
import BlockType from '../../../common/enums/blockType';
import BlockTrigger from '../../../common/enums/blockTrigger';
import FormOption from '../../../common/enums/formOption';
import MultilingualString from '../../../common/models/multilingualString';
import { translate } from '../../../common/service/stringResourceService';
import MetaObjectTreeViewKind from '../../../common/enums/metaObjectTreeViewKind'

var buildSelectOptionsData = function (values) {
	let data = [];
	_.each(values, function (value) {
		data.push({id: value, text: value, isSystem: true})
	});

	return data;
};

var defaultMLS = {
	name: {
		value: 'Name',
	},
	false: {
		value: 'false',
	},
	true: {
		value: 'true',
	},
	md: {
		value: 'md',
	},
	checkbox: {
		value: 'Checkbox',
	},
	null: {
		value: null,
	},
	empty: {
		value: '',
	},
	dblClick: {
		value: 'Double click to open',
	},
	left: {
		value: 'left',
	},
	right: {
		value: 'right',
	},
	start: {
		value: 'start',
	},
	plainText: {
		value: 'Plain text'
	},
	disabledInput: {
		value: 'Disabled input'
	},
	infobox: {
		value: 'Infobox'
	},
	aqua: {
		value: 'aqua'
	},
	deficon: {
		value: 'fa-info'
	},
	tableView: {
		value: 'Table view'
	},
	stretch: {
		value: 'Stretch'
	},
	database: {
		value: 'Database',
	},
	table: {
		value: 'table'
	},
	forty: {
		value: '40'
	},
	threeHundred: {
		value: '300'
	},
	one: {
		value: '1'
	},
	five: {
		value: '5'
	},
	width300: {
		value: '300'
	},
	width768: {
		value: '768'
	},
	classic: {
		value: 'classic'
	},
	select: {
		value: 'select'
	},
	fifty: {
		value: '50'
	}
};
const width = {
	key: PropertiesKey.WIDTH,
	type: PropertiesType.INT,
	relativeOrder: 11,
	group: PropertyGroupKeys.APPEARANCE,
	value: defaultMLS.null,
}
const dontShowByDefault = {
	key: PropertiesKey.DONT_SHOW_BY_DEFAULT,
	type: PropertiesType.BOOLEAN,
	relativeOrder: 105,
	group: PropertyGroupKeys.GENERAL,
	value: defaultMLS.null,
}
const maxRowCount = {
	key: PropertiesKey.MAX_ROW_COUNT,
	type: PropertiesType.INT,
	relativeOrder: 6,
	group: PropertyGroupKeys.GENERAL,
	value: defaultMLS.null,
}
const align = {
	key: PropertiesKey.ALIGN,
	type: PropertiesType.SELECT,
	group: PropertyGroupKeys.APPEARANCE,
	relativeOrder: 100,
	values: buildSelectOptionsData(['start', 'center', 'end']),
	value: {
		value: 'start'
	}
}

const placeholder = {
	key: PropertiesKey.PLACEHOLDER,
	type: PropertiesType.MLS,
	relativeOrder: 93,
	group: PropertyGroupKeys.GENERAL,
	value: defaultMLS.empty,
};

const customCSS = {
	key: PropertiesKey.CSS,
	type: PropertiesType.STRING,
	relativeOrder: 100,
	size: 'long',
	value: defaultMLS.empty,
	group: PropertyGroupKeys.APPEARANCE
};

const customClasses = {
	key: PropertiesKey.CLASSES,
	type: PropertiesType.STRING,
	relativeOrder: 101,
	size: 'long',
	value: defaultMLS.empty,
	group: PropertyGroupKeys.ADVANCED
};

const sharedStyles = {
	key: PropertiesKey.SHARED_STYLES,
	type: PropertiesType.MULTISELECT,
	relativeOrder: 98,
	values: app.sharedStyles && _.map(app.sharedStyles, function (style) {
		return {id: style.id, text: (new MultilingualString(style.name)).toHTML()};
	}),
	value: {value: JSON.stringify([])},
	group: PropertyGroupKeys.APPEARANCE
};

const label = {
	key: PropertiesKey.LABEL,
	type: PropertiesType.MLS,
	value: defaultMLS.empty,
	group: PropertyGroupKeys.GENERAL,
	relativeOrder: 0,
};

const useFieldLabel = {
	key: PropertiesKey.FIELD_LABEL,
	type: PropertiesType.BOOLEAN,
	value: defaultMLS.true,
	relativeOrder: 1,
	group: PropertyGroupKeys.GENERAL,
};

const comment = {
	key: PropertiesKey.COMMENT,
	type: PropertiesType.HTML,
	relativeOrder: 102,
	group: PropertyGroupKeys.ADVANCED,
	value: defaultMLS.empty
};

const open = {
	key: PropertiesKey.OPEN,
	type: PropertiesType.SELECT,
	value: {
		value: 'new.cj.tab'
	},
	values: buildSelectOptionsData(['open.in.new.tab', 'open.link.in.same.window', 'show.nested.window', 'new.cj.tab', 'open.in.same.cj.tab']),
	group: PropertyGroupKeys.GENERAL,
	relativeOrder: 5
};

const createNew = {
	key: PropertiesKey.CREATE_NEW_VIEW,
	type: PropertiesType.SELECT,
	value: {
		value: 'new.cj.tab'
	},
	values: buildSelectOptionsData(['dont.show.create.new','open.in.new.tab', 'show.nested.window', 'new.cj.tab']),
	group: PropertyGroupKeys.GENERAL,
	relativeOrder: 6
};

const commentPlacement = {
	key: PropertiesKey.COMMENT_PLACEMENT,
	type: PropertiesType.SELECT,
	relativeOrder: 103,
	group: PropertyGroupKeys.ADVANCED,
	values: buildSelectOptionsData(['start', 'end', 'top', 'bottom']),
	value: defaultMLS.start
};

const disabled = {
	key: PropertiesKey.DISABLED,
	type: PropertiesType.BOOLEAN,
	relativeOrder: 97,
	group: PropertyGroupKeys.GENERAL,
	value: defaultMLS.false
};

const hideCheckbox = {
	key: PropertiesKey.HIDE_CHECKBOX,
	type: PropertiesType.BOOLEAN,
	relativeOrder: 105,
	group: PropertyGroupKeys.GENERAL,
	value: defaultMLS.false
};

const hideFillfunctions = {
	key: PropertiesKey.HIDE_FILLFUNCTIONS,
	type: PropertiesType.BOOLEAN,
	relativeOrder: 106,
	group: PropertyGroupKeys.GENERAL,
	value: defaultMLS.false
};

const hidden = {
	key: PropertiesKey.HIDDEN,
	type: PropertiesType.BOOLEAN,
	relativeOrder: 98,
	group: PropertyGroupKeys.GENERAL,
	value: defaultMLS.false
};

const required = {
	key: PropertiesKey.REQUIRED,
	type: PropertiesType.BOOLEAN,
	relativeOrder: 96,
	group: PropertyGroupKeys.VALIDATION,
	value: defaultMLS.false
};

const fieldEvents = {
	type: PropertiesType.FIELD_EVENTS,
	group: PropertyGroupKeys.EVENTS,
	transient: true,
	relativeOrder: 108,
}

const readOnly = {
	key: PropertiesKey.READ_ONLY,
	type: PropertiesType.BOOLEAN,
	relativeOrder: 99,
	value: defaultMLS.false
};

const identifier = {
	key: PropertiesKey.IDENTIFIER,
	type: PropertiesType.STRING,
	group: PropertyGroupKeys.ADVANCED,
	relativeOrder: 95,
	value: defaultMLS.empty
};

const readOnlyView = {
	key: PropertiesKey.READ_ONLY_VIEW,
	type: PropertiesType.SELECT,
	values: buildSelectOptionsData(['Plain text', 'Disabled input']),
	relativeOrder: 94,
	group: PropertyGroupKeys.ADVANCED,
	value: defaultMLS.plainText
};

const readOnlyViewForColumns = {
	key: PropertiesKey.READ_ONLY_VIEW,
	type: PropertiesType.SELECT,
	values: buildSelectOptionsData(['Plain text', 'Disabled input']),
	relativeOrder: 94,
	group: PropertyGroupKeys.GENERAL,
	value: defaultMLS.disabledInput
}

const linkFormView = {
	key: PropertiesKey.LINK_FORM_VIEW,
	type: PropertiesType.SELECT,
	relativeOrder: 99,
	link: {
		url: (id) => {
			return app.urls.viewEdit + '/' + id;
		}
	},
	value: defaultMLS.null,
	group: PropertyGroupKeys.GENERAL
}

const formatter = {
	key: PropertiesKey.FORMATTER,
	type: PropertiesType.SELECT,
	value: defaultMLS.null,
	relativeOrder: 95,
	group: PropertyGroupKeys.GENERAL,
	allowClear: true
};

const defaultSorting = {
	key: PropertiesKey.DEFAULT_SORTING,
	type: PropertiesType.SELECT,
	value: {value: 'NONE'},
	group: PropertyGroupKeys.GENERAL,
	values: buildSelectOptionsData(['ASC', 'DESC', 'NONE']),
	relativeOrder: 102
}

const customIndexPage = {
	key: PropertiesKey.CUSTOM_VIEW,
	type: PropertiesType.STRING,
	value: defaultMLS.empty
}

const loadedBlockId = {
	key: PropertiesKey.LOADED_BLOCK_ID,
	type: PropertiesType.SELECT,
	relativeOrder: 101,
	group: PropertyGroupKeys.GENERAL,
	values: (Array.isArray(app.blocks) && _.map(app.blocks.filter(b => (b.ownerId === app.ownerId) && (b.trigger === BlockTrigger.LOADED)), function (block) {
		return {id: block.id, text: block.name};
	})) || [],
	value: defaultMLS.null,
	allowClear: true
}

const nonPrimitiveFieldProperties = [
	required, align,hidden,
	useFieldLabel, placeholder, {
		key: PropertiesKey.CREATE_NEW_VIEW,
		type: PropertiesType.SELECT,
		value: {
			value: 'new.cj.tab'
		},
		values: buildSelectOptionsData(['dont.show.create.new','open.in.new.tab', 'show.nested.window', 'new.cj.tab', 'use.on.create.click.event']),
		group: PropertyGroupKeys.GENERAL,
		relativeOrder: 6
	}, {
		key: PropertiesKey.VIEW,
		type: PropertiesType.SELECT,
		value: defaultMLS.null,
		link: {
			url: (id) => {
				let view = app.views.findWhere({
					id: id
				});
				return app.urls.editView(view.get('id'), view.get('ownerId'));
			},
			name: defaultMLS.tableView
		},
		group: PropertyGroupKeys.APPEARANCE,
		relativeOrder: 101
	}, {
		key: PropertiesKey.DATA_SOURCE,
		type: PropertiesType.SELECT,
		value: defaultMLS.database,
		group: PropertyGroupKeys.ADVANCED,
		relativeOrder: 3
	}, {
		key: PropertiesKey.OPEN,
		type: PropertiesType.SELECT,
		value: {
			value: 'new.cj.tab'
		},
		values: buildSelectOptionsData(['dont.show.view.link', 'open.in.new.tab', 'open.link.in.same.window', 'show.nested.window', 'new.cj.tab', 'open.in.same.cj.tab', 'use.on.click.event']),
		group: PropertyGroupKeys.GENERAL,
		relativeOrder: 5
	},
	{
		key: PropertiesKey.SKIP_NOT_ACCESSIBLE,
		type: PropertiesType.BOOLEAN,
		relativeOrder: 105,
		group: PropertyGroupKeys.ADVANCED,
		value: defaultMLS.false
	}
]
const decimalFieldProperties = [
		required, align,hidden,
		useFieldLabel, placeholder, formatter, {
		key: PropertiesKey.MIN,
		type: PropertiesType.DOUBLE,
		value: defaultMLS.null,
		group: PropertyGroupKeys.VALIDATION,
		relativeOrder: 3
	}, {
		key: PropertiesKey.MAX,
		type: PropertiesType.DOUBLE,
		value: defaultMLS.null,
		group: PropertyGroupKeys.VALIDATION,
		relativeOrder: 4
	}, {
		key: PropertiesKey.STEP,
		type: PropertiesType.DOUBLE,
		value: defaultMLS.null,
		group: PropertyGroupKeys.VALIDATION,
		relativeOrder: 4
	}
]
const booleanFieldProperties = [
	 hidden, required,
	 align, useFieldLabel]
const binaryFieldProperties = [
		required,hidden,
		align, useFieldLabel,  {
			key: PropertiesKey.TYPE,
			type: PropertiesType.MULTISELECT,
			value: {
				value: JSON.stringify(['Any'])
			},
			group: PropertyGroupKeys.GENERAL,
			values: buildSelectOptionsData(['Any', 'Document', 'PDF', 'Image', 'Video', 'Audio', 'Custom']),
			relativeOrder: 3
		}, {
			key: PropertiesKey.CUSTOM_BINARY_EXTENSIONS,
			type: PropertiesType.STRING,
			value: defaultMLS.empty,
			group: PropertyGroupKeys.GENERAL,
			relativeOrder: 4
		}, {
			key: PropertiesKey.UPLOAD,
			type: PropertiesType.SELECT,
			value: {value: 'File'},
			group: PropertyGroupKeys.GENERAL,
			values: buildSelectOptionsData(['File', 'Link', 'File & Link']),
			relativeOrder: 5
		}, {
			key: PropertiesKey.PREVIEW,
			type: PropertiesType.SELECT,
			value: {value: 'Link'},
			group: PropertyGroupKeys.GENERAL,
			values: buildSelectOptionsData(['Link', 'Preview', 'Link & Preview']),
			relativeOrder: 6
		}, {
			key: PropertiesKey.LINK_ACTION,
			type: PropertiesType.SELECT,
			value: {value: 'Download'},
			group: PropertyGroupKeys.GENERAL,
			values: buildSelectOptionsData(['Download', 'Preview']),
			relativeOrder: 7
		},
		{
			key: PropertiesKey.ZOOM,
			type: PropertiesType.BOOLEAN,
			value: defaultMLS.false,
			group: PropertyGroupKeys.GENERAL,
			relativeOrder: 8
		}, {
			key: PropertiesKey.MAX_BINARY,
			type: PropertiesType.INT,
			value: defaultMLS.null,
			group: PropertyGroupKeys.GENERAL,
			relativeOrder: 9
		}, {
			key: PropertiesKey.UPLOAD_FILE_URL,
			type: PropertiesType.STRING,
			value: defaultMLS.null,
			group: PropertyGroupKeys.ADVANCED,
			relativeOrder: 10
		}
]
const columnCommonProperties = [
		identifier, hidden, disabled, customCSS, customClasses, sharedStyles, {
		key: PropertiesKey.SIZE,
		type: PropertiesType.INT,
		value: {
			value: "12"
		},
		visible: false,
		group: PropertyGroupKeys.GENERAL,
		relativeOrder: 1
	}
]
const dateFieldShortProperties = [
		formatter,
		required, align,hidden,
		useFieldLabel, placeholder,
		comment, commentPlacement,
		customClasses, readOnlyView, fieldEvents, {
			key: PropertiesKey.CLIENT_VIEW,
			type: PropertiesType.SELECT,
			value: defaultMLS.null,
			values: [],
			allowClear: true,
			group: PropertyGroupKeys.ADVANCED,
			relativeOrder: 5
		}
]
const dateFieldProperties = [
		...dateFieldShortProperties, {
			key: PropertiesKey.MIN_DATE,
			type: PropertiesType.DATETIME,
			value: defaultMLS.null,
			group: PropertyGroupKeys.VALIDATION,
			relativeOrder: 3
		}, {
			key: PropertiesKey.MAX_DATE,
			type: PropertiesType.DATETIME,
			value: defaultMLS.null,
			group: PropertyGroupKeys.VALIDATION,
			relativeOrder: 4
		}
]

const modalWindowProperties = [
	{
		key: PropertiesKey.MODAL_WIDTH,
		type: PropertiesType.STRING,
		value: { value: '75%' },
		group: PropertyGroupKeys.MODAL_PROPERTIES,
		relativeOrder: 1
	},
	{
		key: PropertiesKey.MODAL_HEIGHT,
		type: PropertiesType.STRING,
		value: { value: '100%' },
		group: PropertyGroupKeys.MODAL_PROPERTIES,
		relativeOrder: 2
	},
	{
		key: PropertiesKey.MODAL_FLOAT,
		type: PropertiesType.SELECT,
		value: { value: 'start' },
		group: PropertyGroupKeys.MODAL_PROPERTIES,
		values: buildSelectOptionsData(['center', 'start-top', 'top', 'end-top', 'end', 'end-bottom', 'bottom', 'start-bottom', 'start']),
		comparator: (l,r) => {
			return 1
		},
		relativeOrder: 3
	}
]
const dateFieldFormProperties = [
		...dateFieldProperties, label, disabled, identifier, sharedStyles, customCSS
]
const dateFieldRowProperties = [
	...dateFieldProperties, readOnly, width, comment, commentPlacement, dontShowByDefault, defaultSorting, readOnlyViewForColumns, customClasses,{
	key: PropertiesKey.HEADER,
	type: PropertiesType.MLS,
	relativeOrder: 2,
	group: PropertyGroupKeys.GENERAL,
	value: defaultMLS.empty
}, {
	key: PropertiesKey.LOCK_RESIZE,
	type: PropertiesType.BOOLEAN,
	value: defaultMLS.false,
	group: PropertyGroupKeys.GENERAL,
	relativeOrder: 102
}
]
const collectionCommonAndListProperties = [
	hideCheckbox, {
		key: PropertiesKey.VIEW,
		type: PropertiesType.SELECT,
		value: defaultMLS.null,
		link: {
			url: (id) => {
				return app.urls.viewEdit + '/' + id;
			},
			name: defaultMLS.tableView
		},
		group: PropertyGroupKeys.GENERAL,
		relativeOrder: 1
	}, {
		key: PropertiesKey.CAN_ADD,
		type: PropertiesType.BOOLEAN,
		value: defaultMLS.true,
		group: PropertyGroupKeys.GENERAL,
		relativeOrder: 2
	}, {
		key: PropertiesKey.CAN_DELETE,
		type: PropertiesType.BOOLEAN,
		value: defaultMLS.true,
		group: PropertyGroupKeys.GENERAL,
		relativeOrder: 3
	}, {
		key: PropertiesKey.WRAP_HEADER_TEXT,
		type: PropertiesType.BOOLEAN,
		value: defaultMLS.false,
		group: PropertyGroupKeys.APPEARANCE,
		relativeOrder: 10
	}, {
		key: PropertiesKey.ROW_HEIGHT,
		type: PropertiesType.INT,
		value: defaultMLS.forty,
		group: PropertyGroupKeys.APPEARANCE,
		relativeOrder: 8
	}, {
		key: PropertiesKey.HEADER_ROW_HEIGHT,
		type: PropertiesType.INT,
		value: defaultMLS.forty,
		group: PropertyGroupKeys.APPEARANCE,
		relativeOrder: 9
	}
]
const virtualAndListProperties = [
	hideFillfunctions,
	{
		key: PropertiesKey.HAS_FOLDER_TREE,
		type: PropertiesType.BOOLEAN,
		value: defaultMLS.false,
		group: PropertyGroupKeys.TREE_VIEW,
		relativeOrder: 106
	}, {
		key: PropertiesKey.TREE_VIEW_KIND,
		type: PropertiesType.SELECT,
		value: {
			value: MetaObjectTreeViewKind.BOTH
		},
		values: Object.values(MetaObjectTreeViewKind).map((kind) => {
			let key = kind.toLowerCase().replace(new RegExp('_', 'g'), '.')
			return {
				key: key,
				id: kind
			}
		}) || [],
		needToBeTranslated: (val) => {
			return translate(val.key)
		},
		group: PropertyGroupKeys.TREE_VIEW,
		relativeOrder: 107
	}, {
		key: PropertiesKey.USE_TREE_ONLY,
		type: PropertiesType.BOOLEAN,
		value: defaultMLS.false,
		group: PropertyGroupKeys.TREE_VIEW,
		relativeOrder: 108
	}, {
		key: PropertiesKey.SHOW_ALL_CHILDREN,
		type: PropertiesType.BOOLEAN,
		value: defaultMLS.false,
		group: PropertyGroupKeys.TREE_VIEW,
		relativeOrder: 109
	}, {
		key: PropertiesKey.IS_TREE_SELECTABLE,
		type: PropertiesType.BOOLEAN,
		value: defaultMLS.false,
		group: PropertyGroupKeys.TREE_VIEW,
		relativeOrder: 110
	}, {
		key: PropertiesKey.SHOW_ONLY_FOLDERS,
		type: PropertiesType.BOOLEAN,
		value: defaultMLS.false,
		group: PropertyGroupKeys.TREE_VIEW,
		relativeOrder: 111
	}
]
const collectionsCommonProperties = [
	identifier, hidden, disabled,
	customCSS, customClasses, sharedStyles, fieldEvents,
	...collectionCommonAndListProperties, {
	key: PropertiesKey.WIDTH_CRITERIA,
	type: PropertiesType.SELECT,
	value: {value: 'constant.width'},
	group: PropertyGroupKeys.GENERAL,
	values: buildSelectOptionsData(['constant.width', 'constant.columns']),
	relativeOrder: 9
},{
	key: PropertiesKey.WIDTH,
	type: PropertiesType.INT,
	value: defaultMLS.width300,
	group: PropertyGroupKeys.GENERAL,
	relativeOrder: 10
},
{
	key: PropertiesKey.USE_CARDS_WITH_TABLE,
	type: PropertiesType.BOOLEAN,
	value: defaultMLS.false,
	group: PropertyGroupKeys.ADVANCED,
	relativeOrder: 104
},
{
	key: PropertiesKey.CARD_VIEW,
	type: PropertiesType.SELECT,
	value: defaultMLS.null,
	group: PropertyGroupKeys.ADVANCED,
	relativeOrder: 105
},
{
	key: PropertiesKey.CARD_TABLE_THRESHOLD,
	type: PropertiesType.INT,
	value: defaultMLS.width768,
	group: PropertyGroupKeys.ADVANCED,
	relativeOrder: 106
},
{
	key: PropertiesKey.WIDGET_HEIGHT,
	type: PropertiesType.INT,
	value: defaultMLS.threeHundred,
	group: PropertyGroupKeys.GENERAL,
	relativeOrder: 7
},
{
	key: PropertiesKey.COLUMNS_QUANTITY,
	type: PropertiesType.INT,
	value: defaultMLS.one,
	group: PropertyGroupKeys.GENERAL,
	relativeOrder: 10
},
{
	key: PropertiesKey.PAGE_SIZE,
	type: PropertiesType.INT,
	value: defaultMLS.five,
	group: PropertyGroupKeys.GENERAL,
	relativeOrder: 11
},
{
	key: PropertiesKey.DATA_SOURCE,
	type: PropertiesType.SELECT,
	value: defaultMLS.database,
	group: PropertyGroupKeys.ADVANCED,
	relativeOrder: 3
}, ...modalWindowProperties
]

const customResources = {
	key: PropertiesKey.CUSTOM_RESOURCES,
	type: PropertiesType.MULTISELECT,
	relativeOrder: 102,
	values: app.customResources && _.map(app.customResources, function (resource) {
		return {id: resource.id, text: resource.binary ? _.escape(resource.binary.fileName) : resource.name.value};
	}),
	value: defaultMLS.null,
};

const seedInstances = {
	key: PropertiesKey.SEED_INSTANCES,
	group: PropertyGroupKeys.SEED_INSTANCES,
	type: PropertiesType.SEED_INSTANCES,
	transient: true,
	relativeOrder: 120
};
const nonPrimitiveFieldFormProperties = [
	...nonPrimitiveFieldProperties, disabled, label, readOnlyView, identifier, sharedStyles, comment, commentPlacement, customCSS, customClasses, fieldEvents, {
	key: PropertiesKey.SELECT_WIDTH,
	type: PropertiesType.INT,
	group: PropertyGroupKeys.APPEARANCE,
	value: defaultMLS.null,
	relativeOrder: 150
},
{
	key: PropertiesKey.CARD_VIEW,
	type: PropertiesType.SELECT,
	value: defaultMLS.null,
	group: PropertyGroupKeys.GENERAL,
	relativeOrder: 107
},
{
	key: PropertiesKey.TEMPLATE,
	type: PropertiesType.SELECT,
	relativeOrder: 106,
	group: PropertyGroupKeys.GENERAL,
	values: buildSelectOptionsData(['select','card']),
	value: defaultMLS.select
},
{
	key: PropertiesKey.SELECTION_TYPE,
	type: PropertiesType.SELECT,
	group: PropertyGroupKeys.GENERAL,
	relativeOrder: 150,
	values: buildSelectOptionsData(['select.component', 'nested.window', 'select.with.extended.view']),
	value: {
		value: 'select.component'
	}
}, ...modalWindowProperties
]

const cssTheme = {
	key: PropertiesKey.CSS_THEME,
	type: PropertiesType.SELECT,
	group: PropertyGroupKeys.APPEARANCE,
	values: (Array.isArray(app.themes) && _.map(app.themes, function (theme) {
		return {id: theme.id, text: new MultilingualString(theme.name).getCurrentValue()};
	})) || [],
	value: defaultMLS.null,
	relativeOrder: 1,
	allowClear: true
}
const 	formEditorViewProperties = [readOnly, {
		key: PropertiesKey.NAME,
		type: PropertiesType.MLS,
		relativeOrder: 0,
	}, cssTheme ,{
		key: PropertiesKey.SHOW_HEADER,
		type: PropertiesType.BOOLEAN,
		value: defaultMLS.true,
		relativeOrder: 3
	}, {
		key: PropertiesKey.SHOW_TOOLBAR,
		type: PropertiesType.BOOLEAN,
		value: defaultMLS.true,
		relativeOrder: 4
	}, {
		key: PropertiesKey.CLIENT_PAGE_TITLE,
		type: PropertiesType.MLS,
		value: defaultMLS.null,
		relativeOrder: 5
	}, {
		key: PropertiesKey.ADD_STRING_VIEW_TO_TITLE,
		type: PropertiesType.BOOLEAN,
		value: defaultMLS.false,
		relativeOrder: 6
	}, {
		key: PropertiesKey.CLIENT_PAGE_HEAD_ENTRIES,
		type: PropertiesType.HTML,
		value: defaultMLS.null,
		relativeOrder: 7
	}, {
		key: PropertiesKey.LOAD_IN_EDIT_MODE,
		type: PropertiesType.BOOLEAN,
		relativeOrder: 100,
		value: defaultMLS.false
	},
	loadedBlockId, {
		key: PropertiesKey.TRACK_CHANGES_ON_PAGE,
		type: PropertiesType.BOOLEAN,
		relativeOrder: 102,
		value: defaultMLS.true
	},
	customResources, {
		key: PropertiesKey.STATIC_RESPONSE,
		type: PropertiesType.MLS,
		size: 'long',
		value: {value: JSON.stringify([])},
		relativeOrder: 103
	}, {
		key: PropertiesKey.USE_STATIC_RESPONSE_FOR_CRAWLER,
		type: PropertiesType.BOOLEAN,
		value: defaultMLS.false,
		relativeOrder: 104
	}, {
		key: PropertiesKey.USE_STATIC_RESPONSE_FOR_BROWSER,
		type: PropertiesType.BOOLEAN,
		value: defaultMLS.false,
		relativeOrder: 105
	}, {
		key: PropertiesKey.DONT_USE_CSS,
		type: PropertiesType.BOOLEAN,
		value: defaultMLS.false,
		relativeOrder: 106
	}]
var properties = {
	wizardViewProperties: [{
		key: PropertiesKey.NAME,
		type: PropertiesType.MLS,
		relativeOrder: 0
	}, {
		key: PropertiesKey.COMMENT,
		type: PropertiesType.MLS,
		size: 'long',
		group: PropertyGroupKeys.GENERAL,
		relativeOrder: 1
	}],
	wizardBlockProperties: [{
		key: PropertiesKey.NAME,
		type: PropertiesType.STRING,
		relativeOrder: 0
	}],
	rowViewItemProperties: [
		required, useFieldLabel, hidden, readOnly, align, width, comment, commentPlacement, defaultSorting, dontShowByDefault, readOnlyViewForColumns, customClasses, {
			key: PropertiesKey.HEADER,
			type: PropertiesType.MLS,
			relativeOrder: 2,
			group: PropertyGroupKeys.GENERAL,
			value: defaultMLS.empty
		}, {
			key: PropertiesKey.VIEW,
			type: PropertiesType.SELECT,
			value: defaultMLS.null,
			group: PropertyGroupKeys.GENERAL,
			relativeOrder: 101
		}, {
			key: PropertiesKey.LOCK_RESIZE,
			type: PropertiesType.BOOLEAN,
			value: defaultMLS.false,
			group: PropertyGroupKeys.GENERAL,
			relativeOrder: 102
		}
	],
	stringViewItemProperties: [
		required, useFieldLabel, hidden, readOnly, align, width, comment, commentPlacement, defaultSorting, formatter, placeholder, dontShowByDefault, readOnlyViewForColumns, customClasses,{
			key: PropertiesKey.HEADER,
			type: PropertiesType.MLS,
			relativeOrder: 2,
			group: PropertyGroupKeys.GENERAL,
			value: defaultMLS.empty
		}, {
			key: PropertiesKey.VIEW,
			type: PropertiesType.SELECT,
			value: defaultMLS.null,
			group: PropertyGroupKeys.GENERAL,
			relativeOrder: 101
		}, {
			key: PropertiesKey.PATTERN,
			type: PropertiesType.STRING,
			group: PropertyGroupKeys.VALIDATION,
			value: defaultMLS.null,
			relativeOrder: 4
		}, {
			key: PropertiesKey.LOCK_RESIZE,
			type: PropertiesType.BOOLEAN,
			value: defaultMLS.false,
			group: PropertyGroupKeys.GENERAL,
			relativeOrder: 110
		}
	],
	widgetEditorViewProperties: [
		loadedBlockId
	],
	formEditorViewProperties: [...formEditorViewProperties],
	formEditorViewPropertiesForReport: [...formEditorViewProperties, {
		key: PropertiesKey.BUILD_ON_LOAD,
		type: PropertiesType.BOOLEAN,
		relativeOrder: 99,
		value: defaultMLS.false
	}],
	toolbarProperties: [identifier, customCSS, customClasses, sharedStyles, {
		key: PropertiesKey.BACK_LINK,
		type: PropertiesType.BOOLEAN,
		value: defaultMLS.true,
		group: PropertyGroupKeys.GENERAL,
		relativeOrder: 1
	}, {
		key: PropertiesKey.FORM_OPTIONS,
		type: PropertiesType.MULTISELECT,
		values: buildSelectOptionsData(['document.post', 'document.relieve', 'show.registers',
			'clone', 'show.history', 'print', 'share', 'download', 'delete']),
		value: {
			value: JSON.stringify(['document.post', 'document.relieve', 'show.registers',
				'clone', 'show.history', 'print', 'share', 'download', 'delete'])
		},
		group: PropertyGroupKeys.GENERAL,
		relativeOrder: 2
	}, {
		key: PropertiesKey.BUTTON_STYLE,
		type: PropertiesType.SELECT,
		values: buildSelectOptionsData(['with.icons', 'without.icons', 'link']),
		value: {
			value: 'with.icons'
		},
		group: PropertyGroupKeys.GENERAL,
		relativeOrder: 3
	}, {
		key: PropertiesKey.DROPDOWN_STYLE,
		type: PropertiesType.SELECT,
		values: buildSelectOptionsData(['with.icons', 'without.icons']),
		value: {
			value: 'with.icons'
		},
		group: PropertyGroupKeys.GENERAL,
		relativeOrder: 4
	}, {
		key: PropertiesKey.VALIGN,
		type: PropertiesType.SELECT,
		values: buildSelectOptionsData(['Stretch', 'Top', 'Center', 'Bottom']),
		value: defaultMLS.stretch,
		group: PropertyGroupKeys.ADVANCED,
		relativeOrder: 5
	}],
	rowProperties: [
		identifier, hidden, disabled, customCSS, customClasses, sharedStyles, hideFillfunctions, {
		key: PropertiesKey.VALIGN,
		type: PropertiesType.SELECT,
		values: buildSelectOptionsData(['Stretch', 'Top', 'Center', 'Bottom']),
		value: defaultMLS.stretch,
		group: PropertyGroupKeys.APPEARANCE,
		relativeOrder: 0
	}],
	groupboxProperties: [
		identifier, hidden, disabled, customCSS, customClasses, sharedStyles, comment, commentPlacement, {
		key: PropertiesKey.TITLE,
		type: PropertiesType.MLS,
		value: defaultMLS.name,
		group: PropertyGroupKeys.GENERAL,
		relativeOrder: 1
	}, {
		key: PropertiesKey.SHOW_HEADER,
		type: PropertiesType.BOOLEAN,
		value: defaultMLS.false,
		group: PropertyGroupKeys.GENERAL,
		relativeOrder: 0
	}, {
		key: PropertiesKey.COLLAPSE,
		type: PropertiesType.BOOLEAN,
		value: defaultMLS.false,
		group: PropertyGroupKeys.GENERAL,
		relativeOrder: 2
	}, {
		key: PropertiesKey.TEMPLATE,
		type: PropertiesType.SELECT,
		values: buildSelectOptionsData(['classic', 'solid', 'thin-line']),
		value: defaultMLS.classic,
		group: PropertyGroupKeys.APPEARANCE,
		relativeOrder: 3
	}, {
		key: PropertiesKey.STYLE,
		type: PropertiesType.SELECT,
		values: buildSelectOptionsData(['default', 'primary', 'warning', 'success', 'danger', 'info', 'none']),
		value: {
			value: 'primary'
		},
		group: PropertyGroupKeys.APPEARANCE,
		relativeOrder: 4
	}, {
		key: PropertiesKey.INITIAL_STATE,
		type: PropertiesType.SELECT,
		values: buildSelectOptionsData(['open', 'collapsed']),
		value: {
			value: 'open'
		},
		needToBeTranslated: (val) => {
			if (val.id == 'open')
				return translate('opened')
			return val.text;
		},
		group: PropertyGroupKeys.GENERAL,
		relativeOrder: 4
	}],
	buttonProperties: [
		identifier, hidden, disabled, comment, commentPlacement, customCSS, customClasses, sharedStyles, align,  {
		key: PropertiesKey.TITLE,
		type: PropertiesType.MLS,
		value: defaultMLS.name,
		group: PropertyGroupKeys.GENERAL,
		relativeOrder: 0
	},{
		key: PropertiesKey.HIDE_TITLE_XS,
		type: PropertiesType.BOOLEAN,
		value: defaultMLS.false,
		group: PropertyGroupKeys.APPEARANCE,
		relativeOrder: 12
	},{
		key: PropertiesKey.BUTTON_COMPACT_MODE,
		type: PropertiesType.BOOLEAN,
		value: defaultMLS.false,
		group: PropertyGroupKeys.APPEARANCE,
		relativeOrder: 13
	},{
		key: PropertiesKey.SIZE,
		type: PropertiesType.SELECT,
		values: buildSelectOptionsData(['xs', 'sm', 'md', 'lg']),
		value: defaultMLS.md,
		group: PropertyGroupKeys.APPEARANCE,
		relativeOrder: 1
	}, {
		key: PropertiesKey.BEFORE_CUSTOM_SERVER_EVENT,
		type: PropertiesType.SELECT,
		values: app.customEvents && _.filter(app.customEvents,
			e => e.blockType == BlockType.CLIENT).map(block => {
			return {id: block.id, text: block.name};
		}),
		value: defaultMLS.null,
		relativeOrder: 2,
		allowClear: true,
		group: PropertyGroupKeys.EVENTS,
		convertTitle: true
	},{
		key: PropertiesKey.CUSTOM_SERVER_EVENT,
		type: PropertiesType.SELECT,
		values: _.map(FormOption.getValues(), option => {
			return {id: option, text: FormOption.getTranslationKey(option), isSystem: true};
		}).concat(app.customEvents ? _.filter(app.customEvents,
			e => e.blockType == BlockType.SERVER).map(block => {
			return {id: block.id, text: block.name, isSystem: false};
		}) : []),
		comparator: (l,r) => {
			if(l.isSystem != r.isSystem) {
				return !!l.isSystem - !!r.isSystem;
			}
			return l.text.toLowerCase().localeCompare(r.text.toLowerCase());
		},
		value: defaultMLS.null,
		relativeOrder: 3,
		allowClear: true,
		group: PropertyGroupKeys.EVENTS,
		convertTitle: true
	}, {
		key: PropertiesKey.AFTER_CUSTOM_SERVER_EVENT,
		type: PropertiesType.SELECT,
		values: app.customEvents && _.filter(app.customEvents,
			e => e.blockType == BlockType.CLIENT).map(block => {
			return {id: block.id, text: block.name};
		}),
		value: defaultMLS.null,
		allowClear: true,
		relativeOrder: 4,
		group: PropertyGroupKeys.EVENTS,
		convertTitle: true
	},{
		key: PropertiesKey.LINK,
		type: PropertiesType.STRING,
		value: defaultMLS.null,
		group: PropertyGroupKeys.GENERAL,
		relativeOrder: 5
	}, {
		key: PropertiesKey.STYLE,
		type: PropertiesType.SELECT,
		values: buildSelectOptionsData(['default', 'primary', 'warning',
			'success', 'danger', 'info', 'link', 'text']),
		value: {
			value: 'primary'
		},
		group: PropertyGroupKeys.APPEARANCE,
		relativeOrder: 6
	}, {
		key: PropertiesKey.ICON,
		type: PropertiesType.ICON,
		value: defaultMLS.null,
		group: PropertyGroupKeys.APPEARANCE,
		relativeOrder: 7
	}, {
		key: PropertiesKey.ICON_COLOR,
		type: PropertiesType.COLOR,
		value: {value:"#a5a5a5"},
		group: PropertyGroupKeys.APPEARANCE,
		relativeOrder: 8
	}, {
		key: PropertiesKey.MIRROR_ICON_IN_RTL,
		type: PropertiesType.BOOLEAN,
		value: defaultMLS.false,
		group: PropertyGroupKeys.APPEARANCE,
		relativeOrder: 9
	}, {
		key: PropertiesKey.ADD_ACTION,
		type: PropertiesType.BUTTON,
		icon: 'fa-plus-circle',
		group: PropertyGroupKeys.ADVANCED,
		relativeOrder: 999
	}, {
		key: PropertiesKey.DROPDOWN_ALIGN,
		type: PropertiesType.SELECT,
		values: buildSelectOptionsData(['left', 'right']),
		value: {
			value: 'left'
		},
		group: PropertyGroupKeys.ADVANCED,
		relativeOrder: 1000
	}],
	tabProperties: [
			identifier, hidden, disabled, customCSS, customClasses, sharedStyles, comment, commentPlacement,
	 {
		key: PropertiesKey.TITLE,
		type: PropertiesType.MLS,
		value: defaultMLS.name,
		group: PropertyGroupKeys.GENERAL,
		relativeOrder: 0
	},
	{
		key: PropertiesKey.ALIGN,
		type: PropertiesType.SELECT,
		relativeOrder: 100,
		values: buildSelectOptionsData(['start', 'end']),
		group: PropertyGroupKeys.APPEARANCE,
		value: defaultMLS.start
	},
	 {
		key: PropertiesKey.STYLE,
		type: PropertiesType.SELECT,
		values: buildSelectOptionsData(['default', 'primary', 'warning', 'success', 'danger', 'info', 'none']),
		value: {
			value: 'none'
		},
		group: PropertyGroupKeys.APPEARANCE,
		relativeOrder: 4
	}],
	columnProperties: [
		...columnCommonProperties
	],
	inlineColumnProperties:[
		...columnCommonProperties,
		align
	],
	booleanFieldRowProperties: [
		...booleanFieldProperties, readOnly, width, comment, commentPlacement, defaultSorting, dontShowByDefault, readOnlyViewForColumns, customClasses,{
		key: PropertiesKey.HEADER,
		type: PropertiesType.MLS,
		relativeOrder: 2,
		group: PropertyGroupKeys.GENERAL,
		value: defaultMLS.empty
	}, {
		key: PropertiesKey.LOCK_RESIZE,
		type: PropertiesType.BOOLEAN,
		value: defaultMLS.false,
		group: PropertyGroupKeys.GENERAL,
		relativeOrder: 102
	}
	],
	booleanFieldFormProperties: [
		...booleanFieldProperties, disabled, label, identifier, comment, customCSS, customClasses, sharedStyles, commentPlacement, fieldEvents,{
			key: PropertiesKey.STYLE,
			type: PropertiesType.SELECT,
			values: buildSelectOptionsData(['aqua', 'green', 'yellow', 'red']),
			value: defaultMLS.null,
			group: PropertyGroupKeys.APPEARANCE,
			relativeOrder: 4
		},{
 	key: PropertiesKey.TEMPLATE,
 	type: PropertiesType.SELECT,
 	value: defaultMLS.checkbox,
 	values: buildSelectOptionsData(['Checkbox', 'Switch']),
	group: PropertyGroupKeys.APPEARANCE,
 	relativeOrder: 3
 }
	],
	embeddedFieldProperties: [
		identifier, hidden, disabled, customCSS, customClasses, sharedStyles, {
		key: PropertiesKey.VIEW,
		type: PropertiesType.SELECT,
		group: PropertyGroupKeys.GENERAL,
		value: defaultMLS.null,
		relativeOrder: 1
	}],
	decimalFieldFormProperties: [
		...decimalFieldProperties, disabled, label, identifier,  sharedStyles, comment, customCSS, customClasses, readOnlyView, commentPlacement, fieldEvents
	],
	decimalFieldRowProperties: [
		...decimalFieldProperties, width, comment, commentPlacement, defaultSorting, dontShowByDefault, readOnly,  readOnlyViewForColumns, customClasses,{
		key: PropertiesKey.HEADER,
		type: PropertiesType.MLS,
		group: PropertyGroupKeys.GENERAL,
		relativeOrder: 2,
		value: defaultMLS.empty
	}, {
		key: PropertiesKey.VIEW,
		type: PropertiesType.SELECT,
		value: defaultMLS.null,
		group: PropertyGroupKeys.GENERAL,
		relativeOrder: 101
	}, {
		key: PropertiesKey.LOCK_RESIZE,
		type: PropertiesType.BOOLEAN,
		value: defaultMLS.false,
		group: PropertyGroupKeys.GENERAL,
		relativeOrder: 102
	}
	],
	dateRangeProperties: [
		identifier, hidden, disabled, formatter, required,label,align, useFieldLabel, sharedStyles,
		comment, customCSS, customClasses, readOnlyView, placeholder, commentPlacement, fieldEvents],
	stringFieldProperties: [
		identifier, hidden, disabled, required,label,align, useFieldLabel, sharedStyles,
		comment, customCSS, customClasses, readOnlyView, placeholder, commentPlacement, formatter, fieldEvents, {
		key: PropertiesKey.MULTILINE,
		type: PropertiesType.BOOLEAN,
		value: defaultMLS.false,
		group: PropertyGroupKeys.APPEARANCE,
		relativeOrder: 2
	}, {
		key: PropertiesKey.SIZE,
		type: PropertiesType.INT,
		value: {
			value: 2
		},
		group: PropertyGroupKeys.APPEARANCE,
		relativeOrder: 3
	}, {
		key: PropertiesKey.PATTERN,
		type: PropertiesType.STRING,
		group: PropertyGroupKeys.VALIDATION,
		value: defaultMLS.null,
		relativeOrder: 4
	}, {
		key: PropertiesKey.MIN,
		type: PropertiesType.INT,
		relativeOrder: 5,
		group: PropertyGroupKeys.VALIDATION,
		value: defaultMLS.null,
	}, {
		key: PropertiesKey.MAX,
		type: PropertiesType.INT,
		group: PropertyGroupKeys.VALIDATION,
		relativeOrder: 6,
		value: defaultMLS.null,
	},{
	key: PropertiesKey.USE_FOR_PASSWORD,
	type: PropertiesType.BOOLEAN,
	value: defaultMLS.false,
	group: PropertyGroupKeys.ADVANCED,
	relativeOrder: 10
}
],
	longStringFieldProperties: [
		identifier, hidden, disabled, required,label,align, useFieldLabel, sharedStyles,
		comment, customCSS, customClasses, readOnlyView, commentPlacement, formatter, fieldEvents, {
		key: PropertiesKey.MULTILINE,
		type: PropertiesType.BOOLEAN,
		value: defaultMLS.true,
		group: PropertyGroupKeys.GENERAL,
		relativeOrder: 2
	}, {
		key: PropertiesKey.MIN,
		type: PropertiesType.INT,
		relativeOrder: 4,
		group: PropertyGroupKeys.GENERAL,
		value: defaultMLS.null,
	}, {
		key: PropertiesKey.MAX,
		type: PropertiesType.INT,
		group: PropertyGroupKeys.GENERAL,
		relativeOrder: 5,
		value: defaultMLS.null,
	}, {
		key: PropertiesKey.SIZE,
		type: PropertiesType.INT,
		value: {
			value: 2
		},
		group: PropertyGroupKeys.GENERAL,
		relativeOrder: 3
	}],
	dateFieldRowProperties: [
		...dateFieldRowProperties
	],
	dateTimeFieldRowProperties: [
		...dateFieldRowProperties,{
			key: PropertiesKey.MINUTES_STEP,
			type: PropertiesType.INT,
			value: {
				value: "1"
			},
			group: PropertyGroupKeys.GENERAL,
			relativeOrder: 5
		}
	],
	durationFieldRowProperties: [
		...dateFieldRowProperties
	],
	dateFieldFormProperties: [
		...dateFieldFormProperties
	],
	dateTimeFieldFormProperties: [
		...dateFieldFormProperties, {
			key: PropertiesKey.MINUTES_STEP,
			group: PropertyGroupKeys.VALIDATION,
			type: PropertiesType.INT,
			value: {
				value: "1"
			},
			relativeOrder: 5
		}
	],
	durationAndZoneOffsetFieldFormProperties: [
		...dateFieldShortProperties, disabled, identifier, sharedStyles, customCSS, label
	],
	nonPrimitiveFieldFormProperties: [
		...nonPrimitiveFieldFormProperties
	],
	directoryFieldFormProperties: [
		...nonPrimitiveFieldFormProperties, seedInstances
	],
	nonPrimitiveFieldRowProperties: [
		...nonPrimitiveFieldProperties, readOnly, width, comment, commentPlacement, defaultSorting, dontShowByDefault, linkFormView, readOnlyViewForColumns, customClasses,{
			key: PropertiesKey.HEADER,
			type: PropertiesType.MLS,
			relativeOrder: 2,
			group: PropertyGroupKeys.GENERAL,
			value: defaultMLS.empty
		},	{
				key: PropertiesKey.MODAL_WIDTH,
				type: PropertiesType.STRING,
				value: { value: '75%' },
				group: PropertyGroupKeys.MODAL_PROPERTIES,
				relativeOrder: 106
			},
			{
				key: PropertiesKey.MODAL_HEIGHT,
				type: PropertiesType.STRING,
				value: { value: '100%' },
				group: PropertyGroupKeys.MODAL_PROPERTIES,
				relativeOrder: 107
			},
			{
				key: PropertiesKey.MODAL_FLOAT,
				type: PropertiesType.SELECT,
				value: { value: 'start' },
				group: PropertyGroupKeys.MODAL_PROPERTIES,
				values: buildSelectOptionsData(['center', 'start-top', 'top', 'end-top', 'end', 'end-bottom', 'bottom', 'start-bottom', 'start']),
				comparator: (l,r) => {
					return 1
				},
				relativeOrder: 108
			}, {
				key: PropertiesKey.LOCK_RESIZE,
				type: PropertiesType.BOOLEAN,
				value: defaultMLS.false,
				group: PropertyGroupKeys.GENERAL,
				relativeOrder: 109
			}, {
				key: PropertiesKey.SELECTION_TYPE,
				type: PropertiesType.SELECT,
				group: PropertyGroupKeys.GENERAL,
				relativeOrder: 150,
				values: buildSelectOptionsData(['select.component', 'nested.window', 'select.with.extended.view']),
				value: {
					value: 'select.component'
				}
			}
	],
	collectionFieldRowProperties: [
		width, comment, hidden, commentPlacement, dontShowByDefault, useFieldLabel, readOnlyViewForColumns, customClasses,{
			key: PropertiesKey.HEADER,
			type: PropertiesType.MLS,
			relativeOrder: 2,
			group: PropertyGroupKeys.GENERAL,
			value: defaultMLS.empty
		}, {
			key: PropertiesKey.LOCK_RESIZE,
			type: PropertiesType.BOOLEAN,
			value: defaultMLS.false,
			group: PropertyGroupKeys.GENERAL,
			relativeOrder: 102
		}
	],
	dynamicFieldFormProperties: [
		identifier, disabled, hidden, required, label, readOnlyView,  comment, commentPlacement,
		align, useFieldLabel, sharedStyles, customCSS, customClasses, fieldEvents, 
		{
			key: PropertiesKey.OPEN,
			type: PropertiesType.SELECT,
			value: {
				value: 'new.cj.tab'
			},
			values: buildSelectOptionsData(['dont.show.view.link', 'open.in.new.tab', 'open.link.in.same.window', 'show.nested.window', 'new.cj.tab', 'open.in.same.cj.tab']),
			group: PropertyGroupKeys.GENERAL,
			relativeOrder: 5
		}, createNew, ...modalWindowProperties
	],
	dynamicFieldRowProperties: [
		required, useFieldLabel, hidden, readOnly, align, width, comment, commentPlacement, dontShowByDefault, readOnlyViewForColumns, customClasses,{
			key: PropertiesKey.HEADER,
			type: PropertiesType.MLS,
			relativeOrder: 2,
			group: PropertyGroupKeys.GENERAL,
			value: defaultMLS.empty
		}, 
		{
			key: PropertiesKey.OPEN,
			type: PropertiesType.SELECT,
			value: {
				value: 'new.cj.tab'
			},
			values: buildSelectOptionsData(['dont.show.view.link', 'open.in.new.tab', 'open.link.in.same.window', 'show.nested.window', 'new.cj.tab', 'open.in.same.cj.tab']),
			group: PropertyGroupKeys.GENERAL,
			relativeOrder: 5
		}, createNew, {
			key: PropertiesKey.LOCK_RESIZE,
			type: PropertiesType.BOOLEAN,
			value: defaultMLS.false,
			group: PropertyGroupKeys.GENERAL,
			relativeOrder: 102
		}
	],
	binaryFieldFormProperties: [
		...binaryFieldProperties, disabled, label, identifier, sharedStyles,
		customCSS, customClasses, comment, commentPlacement, fieldEvents
	],
	binaryFieldRowProperties: [
		...binaryFieldProperties, readOnly, width, comment, commentPlacement, defaultSorting, dontShowByDefault, readOnlyViewForColumns, customClasses,{
			key: PropertiesKey.HEADER,
			type: PropertiesType.MLS,
			relativeOrder: 2,
			group: PropertyGroupKeys.GENERAL,
			value: defaultMLS.empty
		}, {
			key: PropertiesKey.VIEW,
			type: PropertiesType.SELECT,
			value: defaultMLS.null,
			group: PropertyGroupKeys.GENERAL,
			relativeOrder: 101
		}, {
			key: PropertiesKey.LOCK_RESIZE,
			type: PropertiesType.BOOLEAN,
			value: defaultMLS.false,
			group: PropertyGroupKeys.GENERAL,
			relativeOrder: 102
		}
	],
	collectionProperties: [
		...collectionsCommonProperties, maxRowCount,
		readOnlyView,useFieldLabel, label, seedInstances, comment, commentPlacement, linkFormView, {
			key: PropertiesKey.OPEN,
			type: PropertiesType.SELECT,
			value: {
				value: 'new.cj.tab'
			},
			values: buildSelectOptionsData(['dont.open.on.click', 'open.in.new.tab', 'open.link.in.same.window', 'show.nested.window', 'new.cj.tab', 'open.in.same.cj.tab']),
			group: PropertyGroupKeys.GENERAL,
			relativeOrder: 5
		}, {
			key: PropertiesKey.CREATE_NEW_VIEW,
			type: PropertiesType.SELECT,
			value: {
				value: 'new.cj.tab'
			},
			values: buildSelectOptionsData(['dont.show.create.new', 'open.in.new.tab', 'show.nested.window', 'new.cj.tab', 'use.on.create.click.event']),
			group: PropertyGroupKeys.GENERAL,
			relativeOrder: 5
		}, {
			key: PropertiesKey.WHICH_ROW_LOAD,
			type: PropertiesType.SELECT,
			value: defaultMLS.null,
			values: buildSelectOptionsData(['row.load.from.field', 'row.load.from.index.table']),
			group: PropertyGroupKeys.GENERAL,
			relativeOrder: 5,
			allowClear: true
		},
		{
			key: PropertiesKey.TEMPLATE,
			type: PropertiesType.SELECT,
			relativeOrder: -1,
			group: PropertyGroupKeys.GENERAL,
			values: buildSelectOptionsData(['table', 'multiselect', 'card.grid']),
			value: defaultMLS.table
		},
		{
			key: PropertiesKey.WIDGET_HEIGHT,
			type: PropertiesType.INT,
			value: defaultMLS.threeHundred,
			group: PropertyGroupKeys.GENERAL,
			relativeOrder: 7
		},
		{
			key: PropertiesKey.COLUMNS_QUANTITY,
			type: PropertiesType.INT,
			value: defaultMLS.one,
			group: PropertyGroupKeys.GENERAL,
			relativeOrder: 10
		},
		{
			key: PropertiesKey.PAGE_SIZE,
			type: PropertiesType.INT,
			value: defaultMLS.five,
			group: PropertyGroupKeys.GENERAL,
			relativeOrder: 11
		},
		{
			key: PropertiesKey.HAS_VIEW_LINK,
			type: PropertiesType.BOOLEAN,
			value: defaultMLS.true,
			group: PropertyGroupKeys.GENERAL,
			relativeOrder: 101
		},
		{
			key: PropertiesKey.SELECTION_TYPE,
			type: PropertiesType.SELECT,
			group: PropertyGroupKeys.GENERAL,
			relativeOrder: 150,
			values: buildSelectOptionsData(['select.component', 'nested.window', 'select.with.extended.view']),
			value: {
				value: 'select.component'
			}
		}
	],
	virtualCollectionProperties: [
			...collectionsCommonProperties, open, linkFormView,
			{
				key: PropertiesKey.HAS_POST_COLUMN,
				type: PropertiesType.BOOLEAN,
				value: defaultMLS.true,
				group: PropertyGroupKeys.GENERAL,
				relativeOrder: 8
			}, ...virtualAndListProperties,
			{
				key: PropertiesKey.TEMPLATE,
				type: PropertiesType.SELECT,
				relativeOrder: -1,
				group: PropertyGroupKeys.GENERAL,
				values: buildSelectOptionsData(['table', 'card.grid']),
				value: defaultMLS.table
			},
			{
				key: PropertiesKey.CREATE_NEW_VIEW,
				type: PropertiesType.SELECT,
				value: {
					value: 'new.cj.tab'
				},
				values: buildSelectOptionsData(['open.in.new.tab', 'show.nested.window', 'new.cj.tab']),
				group: PropertyGroupKeys.GENERAL,
				relativeOrder: 5
			}, {
			key: PropertiesKey.LOAD_MORE_SIZE_BASE_VALUE,
			type: PropertiesType.STRING,
			value: defaultMLS.fifty,
			group: PropertyGroupKeys.ADVANCED,
			relativeOrder: 3
		}
	],
	listViewProperties: [
		...collectionCommonAndListProperties, ...virtualAndListProperties, linkFormView, cssTheme, open, {
			key: PropertiesKey.CREATE_NEW_VIEW,
			type: PropertiesType.SELECT,
			value: {
				value: 'new.cj.tab'
			},
			values: buildSelectOptionsData(['open.in.new.tab', 'show.nested.window', 'new.cj.tab']),
			group: PropertyGroupKeys.GENERAL,
			relativeOrder: 5
		}
	],
	embeddedCollectionProperties: [
		...collectionsCommonProperties, maxRowCount,
		{
			key: PropertiesKey.CAN_REORDER,
			type: PropertiesType.BOOLEAN,
			value: defaultMLS.true,
			group: PropertyGroupKeys.GENERAL,
			relativeOrder: 8
		},
		{
			key: PropertiesKey.TEMPLATE,
			type: PropertiesType.SELECT,
			relativeOrder: -1,
			group: PropertyGroupKeys.GENERAL,
			values: buildSelectOptionsData(['table', 'card.grid']),
			value: defaultMLS.table
		},
		{
			key: PropertiesKey.HAS_VIEW_LINK,
			type: PropertiesType.BOOLEAN,
			value: defaultMLS.true,
			group: PropertyGroupKeys.GENERAL,
			relativeOrder: 101
		}
	],
	separatorProperties: [
		identifier, hidden,
		sharedStyles, customCSS, customClasses
	],
	tabContainerProperties: [
		sharedStyles, customCSS, customClasses, identifier, hidden,
	],
	reportTableProperties: [],
	textProperties: [
		identifier, hidden,
		customCSS, customClasses,
		sharedStyles, {
		key: PropertiesKey.LABEL,
		type: PropertiesType.HTML,
		value: defaultMLS.dblClick,
		onlyButton: 'true',
		group: PropertyGroupKeys.GENERAL,
		relativeOrder: 0
	},	{
		key: PropertiesKey.DONT_TRANSLATE,
		type: PropertiesType.BOOLEAN,
		value: defaultMLS.false,
		group: PropertyGroupKeys.GENERAL,
		relativeOrder: 8
	}
],
	widgetProperties: [
		identifier, hidden,
		customCSS, customClasses, disabled,
		sharedStyles, {
			key: PropertiesKey.TEMPLATE,
			type: PropertiesType.SELECT,
			value: defaultMLS.infobox,
			values: buildSelectOptionsData(['Infobox', 'Solid infobox', 'Small box']),
			group: PropertyGroupKeys.APPEARANCE,
			relativeOrder: 1
		}, {
			key: PropertiesKey.STYLE,
			type: PropertiesType.SELECT,
			values: buildSelectOptionsData(['aqua', 'green', 'yellow', 'red']),
			value: defaultMLS.aqua,
			group: PropertyGroupKeys.APPEARANCE,
			relativeOrder: 2
		}, {
			key: PropertiesKey.LINK,
			type: PropertiesType.STRING,
			value: defaultMLS.null,
			group: PropertyGroupKeys.GENERAL,
			relativeOrder: 3
		}, {
			key: PropertiesKey.ICON,
			type: PropertiesType.ICON,
			value: defaultMLS.deficon,
			group: PropertyGroupKeys.APPEARANCE,
			relativeOrder: 3
		}, {
			key: PropertiesKey.MIRROR_ICON_IN_RTL,
			type: PropertiesType.BOOLEAN,
			value: defaultMLS.false,
			group: PropertyGroupKeys.APPEARANCE,
			relativeOrder: 4
		}, {
			key: PropertiesKey.TITLE,
			type: PropertiesType.MLS,
			value: defaultMLS.empty,
			group: PropertyGroupKeys.GENERAL,
			relativeOrder: 4
		}
],
clientViewDateTimeProperties: [
	{
		key: PropertiesKey.DURATION_FORMATTER,
		type: PropertiesType.SELECT,
		value: defaultMLS.null,
		relativeOrder: 2,
		allowClear: true
	},
	{
		key: PropertiesKey.SHOW_DATE_TIME_IN_POPOVER,
		type: PropertiesType.BOOLEAN,
		value: defaultMLS.true,
		relativeOrder: 3
	}
],
htmlFieldRowProperties: [
		required, useFieldLabel, hidden, align,
		readOnly, width, defaultSorting, comment, commentPlacement, dontShowByDefault, readOnlyViewForColumns, customClasses,{
			key: PropertiesKey.HEADER,
			type: PropertiesType.MLS,
			value: defaultMLS.empty,
			group: PropertyGroupKeys.GENERAL,
			relativeOrder: 2
		}, {
			key: PropertiesKey.HTML_STRING_POPOVER,
			type: PropertiesType.BOOLEAN,
			value: defaultMLS.true,
			group: PropertyGroupKeys.GENERAL,
			relativeOrder: 202
		}, {
			key: PropertiesKey.LOCK_RESIZE,
			type: PropertiesType.BOOLEAN,
			value: defaultMLS.false,
			group: PropertyGroupKeys.GENERAL,
			relativeOrder: 102
		}
],
defaultFieldFormProperties: [
	customCSS, sharedStyles
],
	customIndexPageProperties: [
		customIndexPage
	],
	jsonViewProperties: [
		{
			key: PropertiesKey.FIELD_NAME,
			type: PropertiesType.STRING,
			value: defaultMLS.null,
			relativeOrder: 0,
			group: PropertyGroupKeys.GENERAL
		}, {
			key: PropertiesKey.JSON_VIEW_ITEM_FIELD_SERIALIZE_KIND,
			type: PropertiesType.SELECT,
			values: [],
			relativeOrder: 1,
			group: PropertyGroupKeys.GENERAL
		}, {
			key: PropertiesKey.VIEW,
			type: PropertiesType.SELECT,
			value: defaultMLS.null,
			values: [],
			group: PropertyGroupKeys.GENERAL,
			relativeOrder: 2,
			allowClear: true,
			link: {
				url: (id) => {
					let view = app.views.findWhere({
						id: id
					});
					return app.urls.editView(view.get('id'), view.get('ownerId'));
				}
			}
		}, {
			key: PropertiesKey.VIEW_SOURCE,
			type: PropertiesType.SELECT,
			values: [],
			group: PropertyGroupKeys.GENERAL,
			relativeOrder: 3,
		}, {
			key: PropertiesKey.ADD_OBJECT_TYPE,
			type: PropertiesType.BOOLEAN,
			relativeOrder: 4,
			group: PropertyGroupKeys.GENERAL,
			value: defaultMLS.false
		}, {
			key: PropertiesKey.PRIMITIVE_FIELD,
			type: PropertiesType.SELECT,
			values: [],
			group: PropertyGroupKeys.GENERAL,
			relativeOrder: 5,
		}, {
			key: PropertiesKey.BINARY_FIELD_SERIALIZE_METADATA,
			type: PropertiesType.BOOLEAN,
			relativeOrder: 6,
			group: PropertyGroupKeys.GENERAL,
			value: defaultMLS.true
		}, {
			key: PropertiesKey.BINARY_FIELD_SERIALIZE_URL,
			type: PropertiesType.BOOLEAN,
			relativeOrder: 7,
			group: PropertyGroupKeys.GENERAL,
			value: defaultMLS.false
		}, {
			key: PropertiesKey.JSON_VIEW_ITEM_PRIMITIVE_FIELD_SERIALIZE_KIND,
			type: PropertiesType.SELECT,
			relativeOrder: 8,
			group: PropertyGroupKeys.GENERAL,
			values: []
		}, {
			key: PropertiesKey.FORMATTER,
			type: PropertiesType.SELECT,
			relativeOrder: 9,
			group: PropertyGroupKeys.GENERAL,
			allowClear: true,
			values: []
		}
	]
};

export default properties;
