import entityManager from '../../common/components/entityManager.js';
import utils, {openLink, showInstance} from '../../common/components/utils.js';
import Entities from '../../common/collections/entities.js';
import CollectionTable from './collectionTable.js';
import FieldSelect from '../../common/components/fieldSelect.js';
import States from '../../common/enums/states';
import { translate } from '../../common/service/stringResourceService'

var FieldTable = CollectionTable.extend({

	baseClass: Entities,

	initialize: function (options) {
		this.typeId = utils.getTypeId(this.$el)
		CollectionTable.prototype.initialize.apply(this, arguments);
		const tableEl = this.$el.find('table')
		this._get('addSelect').attr('placeholder', translate('please.select'))
		this._get('addSelect').attr('data-create-new-action', tableEl.attr('data-create-new-action') || 'new.cj.tab')
		this._get('addSelect').attr('data-use-viewer', tableEl.attr('data-use-viewer') || 'new.cj.tab')
		this._get('addSelect').attr('data-source', tableEl.attr('data-source'))
		this.openMode = tableEl.attr('data-use-viewer') || 'new.cj.tab';


		this.initializeSelect()
	},

	initializeSelect() {
		const selectModel = new Backbone.Model();
		this.select = new FieldSelect({
			model: selectModel,
			modelAttr: this.fieldName,
			typeId: this.typeId,
			el: this._get('addSelect'),
			context: this.context
		});
		selectModel.on('manualChange:' + this.fieldName , _.bind(this.onItemSelected, this));
	},

	addRow: function(){
		this.setIgnoreValuesIntoSelect();
		this.select.open(this);
	},

	onItemSelected: function () {
		var that = this;
		var id = this.select.getValue() ? this.select.getValue().id : null;
		if (id == null) {
			return
		}
		this.select.setValue(null);
		var rowModel = this.model.get(id);
		if (!rowModel && id) {
			entityManager
				.fetchRowView(this.typeId, this.viewId, id, this.fieldId, this.$el.attr('data-which-row-load'))
				.then(function (row) {
					row.clientState = States.NEW;
					that.model.add(row);
				});
		} else if (rowModel && rowModel.get('clientState') != States.NEW) {
			rowModel.set('clientState', null);
		} else {
			app.notificationManager.addError('Choose item!');
		}
	},

	removeRows: function () {
		var ids = this.getSelectedIds();
		this.newTable.setSelection([])
		var models = this.model.filter(function (model) {
			return $.inArray(model.getIdOrCid(), ids) >= 0
		});
		this.model.remove(models)
		this.redraw();

	},
	onClickRow: function (row,e) {
		let target=$(e.target)
		if (!((target.parents('a')[0])||e.target.nodeName.toLowerCase()=='a') && this.openMode != 'dont.open.on.click') {
			let cell
			if (target.hasClass('table-cell')){
				cell=target
			}else{
				cell=target.parents('div.table-cell')
			}
			if (target.is('.ref')) {
				openLink(target, e, this.model.get(row.id));
			} else if (!(cell.find('button')[0]) && !app.types.get(this.typeId||row.entityTypeId).isTransient()){
				let objectId = row.id
				if (this.isExternal) {
					objectId = row.urlString
				}
				showInstance({openMode: this.openMode,
					objectId: objectId,
					edit: this.context.updating,
					typeId: this.typeId||row.entityTypeId,
					viewId: this.linkFormView,
					callback: (data) => this._updateModel(data),
					openInNewTab: e.button == 1,
					previousContext: this.context,
					modalOpt: this.modalOpt
				})
			}
		}
	},
	setIgnoreValuesIntoSelect: function () {
		this.select.setIgnoreValues(_.filter(this.model.toJSON(), function (item) {
			return item.clientState !== States.DELETED;
		}));
	}
});

export default FieldTable;
