import PrimitiveEntityType from '../../common/enums/primitiveEntityType';
import BinaryInput from '../../common/components/binaryInput';
import FieldKind from '../../common/enums/fieldKind';
import TypeKind from '../../common/enums/typeKind';
import VirtualCollection from '../components/virtualCollection';
import BaseForm from '../../common/views/baseForm.js';
import FieldInput from '../../common/components/fieldInput.js';
import FieldTemporalAccesorInput from '../components/fieldTemporalAccesorInput.js';
import FieldTemporalAmountInput from '../components/fieldTemporalAmountInput.js';
import DateRangeInput from '../components/dateRangeInput.js';
import MultilingualInputField from '../../common/components/multilingualInputField.js';
import MetaObjectStateSelect from '../../common/components/metaObjectStateSelect.js';
import MultilingualHtmlInputField from '../../common/components/multilingualHtmlInputField.js';
import FieldSelect from '../../common/components/fieldSelect.js';
import FieldMultiselect from '../../common/components/fieldMultiselect';
import DayOfWeekSelect from '../components/dayOfWeekSelect.js';
import FieldDynamic from '../../common/components/fieldDynamic.js';
import FieldTable from '../components/fieldTable.js';
import EmbeddedFieldTable from '../components/fieldEmbeddedTable.js';
import TriggerButton from '../components/triggerButton.js';
import TabContainer from '../components/tabContainer.js';
import MultilingualString from '../../common/models/multilingualString.js';
import ToggleSwitch from '../../common/components/toggleSwitch.js'
import CheckboxInput from '../../common/components/checkboxInput.js'
import Widget from '../../entity/components/Widget.js'
import Formatter from '../../common/components/formatter';
import entityManager from '../../common/components/entityManager.js';
import utils, { buildDefaultPopover } from '../../common/components/utils'
import GroupBox from "../../common/components/groupbox";
import TimelineTable from '../../documentTimeline/components/timelineTable.js'

var getChildModel = function (model, element) {
	var fields = utils.getDataFieldAttr($(element)).split('.');
	var result = {};
	var i = 0;
	for (; i < fields.length - 1; ++i) {
		const fieldName = fields[i];
		model = model.get(fieldName);
	}
	result.attr = fields[i];
	result.model = model;
	if (fields.length > 1) {
		result.parentField = fields[i - 1];
	}
	return result;
};

var FormView = BaseForm.extend({

	initialize: function (options) {
		this.context = options.context;
		this.model = this.context.model;
		this.originalModel = {};
		this.isTrackingOfUnsavedChangesOn = false;
		this.isTrackingOfUnsavedChangesBlocked = !options.context.preventPageLeave;
		this.tabs = []
		this.subTabs = {}
		this.inputs = [];
		this.selects = [];
		this.tables = [];
		this.embeddedTables = [];
		this.virtualTables = [];
		this.filters = [];
		this.triggerButtons = [];
		this.widgets = [];
		this.groupBoxes = [];
		this.everything = [
			this.inputs,
			this.selects,
			this.tables,
			this.embeddedTables,
			this.widgets
		]

		this.fieldsByViewId = new Map()
		this.validationContext = options.context.validationContext;
		this.initFormToolbarTriggers = this.initFormToolbarTriggers || options.initFormToolbarTriggers;
		FormView.__super__.initialize.apply(this, arguments);
		buildDefaultPopover(this.$el.find('.popover-tooltip'));
		this.initializeInputs();
		this.initializeMultilingualInputs();
		this.initializeBinaryInputs();
		this.initializeTemporalAmountInputs();
		this.initializeSelects();
		this.initializeTables();
		this.initializeVirtualTables();
		this.initializeEmbeddedTables();
		this.initializeTriggerButtons();
		this.initializeTabContainers();
		this.initializeGroupBoxes();
		this.activateValidation();
		this.onInitComplete();
		this.initializeCjgField()
		this.initializeWidgets()
		this.initializeDocumentTimelineTable()
		utils.preventPageLeave(() => {
			return !this.isTrackingOfUnsavedChangesBlocked && this.isTrackingOfUnsavedChangesOn && this.haveChanges();
		}, () => {
			return this.context.wizardView.save(false)
		}, this.context.viewControl);
	},
	haveChanges () {
		return !this.model.deepEquals(this.originalModel)
	},
	initializeAsyncComponents () {
		let promises = _.union(this.tables, this.embeddedTables, this.virtualTables).map(table => table.initializeTableAsync());
		return Promise.all(promises)
	},

	initializeInputs: function () {
		var that = this;
		this.$('input[data-field], textarea[data-field]')
			.not('.modal input, .modal textarea, [data-is-string-multilingual], .html-string')
			.each(function (event) {
				const viewItemId = that.getViewItemId(this)
				var model = getChildModel(that.model, this);
				const primitiveType = utils.getPrimitiveType($(this));
				var input;
				let initObj = {
					model: model.model,
					modelAttr: model.attr,
					el: this,
					context: that.context
				}
				switch (primitiveType) {
					case PrimitiveEntityType.MONTH:
					case PrimitiveEntityType.TIMESTAMP:
					case PrimitiveEntityType.LOCAL_DATE:
					case PrimitiveEntityType.LOCAL_TIME:
					case PrimitiveEntityType.LOCAL_DATE_TIME:
					case PrimitiveEntityType.MONTH_DAY:
					case PrimitiveEntityType.YEAR:
					case PrimitiveEntityType.YEAR_MONTH:
            input = new FieldTemporalAccesorInput(initObj)
						break;
					case PrimitiveEntityType.ZONE_OFFSET:
					case PrimitiveEntityType.DURATION:
					case PrimitiveEntityType.PERIOD:
            input = new FieldTemporalAmountInput(initObj)
						break;
          case PrimitiveEntityType.BOOLEAN:
            if (this.parentElement.tagName === 'DIV') {
              input = new ToggleSwitch(initObj)
            } else {
              input = new CheckboxInput(initObj)
            }
            break;
					case PrimitiveEntityType.LOCAL_DATE_RANGE:
						input = new DateRangeInput(initObj)
						break;
					default:
            input = new FieldInput(initObj)
						break;
				}
				that.fieldsByViewId.set(viewItemId, input)
				that.inputs.push(input);
			});
	},
	addToFieldsByViewId(elem, object) {
		const id = this.getViewItemId(elem)
		if (id) {
			this.fieldsByViewId.set(id, object)
		}
	},

	getViewItemId (elem) {
		let parentNode = elem.parentNode
		while (parentNode && !parentNode.classList.contains('form-group')){
			parentNode = parentNode.parentNode
		}
		return parentNode && parentNode.getAttribute('data-item-id')
	},

	initializeWidgets () {
		let that = this
		this.$('div.widget-wrapper').each(function(index, tag) {
			var model = getChildModel(that.model, this);
			const field = app.fields.get(tag.getAttribute('data-field-id'))
			let threshold = parseInt(this.getAttribute('card-table-threshold'))
			if (threshold == NaN) {
				threshold = 768
			}
			let toolbar = [].find.call(this.parentNode.children, (c) => {return c.classList.contains('card-toolbar' + field.id)})
			if (this.classList.contains('init-xs')) {
				if (window.screen.width >= threshold) {
					if (toolbar) toolbar.style.display = 'none'
					this.style.display = 'none'
					return
				} else {
					toolbar.style.removeProperty("display")
				}
			}
			let initObj = {
				model: model.model,
				modelAttr: model.attr,
				el: this,
				context: that.context,
				field: field
			}
			const widget = new Widget(initObj)
			that.widgets.push(widget)
			that.addToFieldsByViewId(this, widget)
		})
	},

	initializeCjgField() {
		this.$('cjgfield[f]').each((index, tag) => {
			let model = this.model
			let identifier = tag.getAttribute('f')
			let field
			if (identifier.includes('.')) {
				const firstPart = identifier.slice(0, identifier.indexOf('.'))
				const secondPart = identifier.slice(identifier.indexOf('.') + 1)
				let parentfield = app.fields.get(firstPart) || app.types.get(this.context.typeId).fieldByName(firstPart)
				field = parentfield.type().fields().find((f) => {return f.systemName() == secondPart || f.get("id") == secondPart})
				model = model.get(parentfield.fieldName())
			} else {
				field = app.fields.get(identifier) || app.types.get(this.context.typeId).fieldByName(identifier)
			}
			const fieldName = field.fieldName()
			let update = () => {}
			let changeHandler = (callback) => {
				model.on('change:' + fieldName, callback)
			}
			if (!field.isDynamic() && field.type().isPrimitive()) {
				switch (field.type().primitive()) {
					case PrimitiveEntityType.BINARY:
						tag.setAttribute('data-file-preview','Preview')
						tag.setAttribute('data-upload','')
						tag.setAttribute('data-disabled','true')
						const input = new BinaryInput({
							model: model,
							modelAttr: fieldName,
							el: tag
						})
						input.render()
						break
					case PrimitiveEntityType.STRING:
						if (field.type().stringHasTranslations()) {
							changeHandler = (callback) => {
								model.get(fieldName) &&
								model.get(fieldName).get('translations') &&
								model.get(fieldName).get('translations').on('change', callback)
							}
						} else {
							changeHandler = (callback) => {
								model.get(fieldName) &&
								model.get(fieldName).on('change', callback)
							}
						}
					default:
						update = () => {
							const isHTMLString = field.type().isStringHTML() || false
							let m = model.get(fieldName)
							if (m && m.toServerJSON) {
								m = m.toServerJSON()
							}
							tag.innerHTML = Formatter.formatToHTML(m, {
															type: field.type(),
															isEditMode: false,
															isHTMLString: isHTMLString
														})
						}
				}
			} else if (field.isDynamic() || field.type().isDictionary()) {
				update = () => {
					const id = model.get(fieldName) && model.get(fieldName).id
					if (id) {
						entityManager.fetchStringView(null, id).then((text) => {
							tag.innerHTML = text
						})
					}
				}
			}
			changeHandler(update)
			update()
		})
	},
	findSubTabByViewId(viewItemId) {
		return this.subTabs[viewItemId]
	},
	findComponentObjectByViewId(identifier) {
		return this.fieldsByViewId.get(identifier)
	},
	findComponentObject (identifier) {
		const field = app.fields.get(identifier) || app.types.get(this.context.typeId).fieldByName(identifier)
		if (!field) {
			return
		}
		let object = null
		for (const arr of this.everything) {
			object = arr.find((ob) => {
				return ob.modelAttr == field.fieldName()
			})
			if (object) {
				break
			}
		}
		return object
	},

	activateValidation() {
		this._eachControl(c => {
			let input = $(c.el);
			if (!input.is('[data-field]')) {
				input = input.find('[data-field]').first();
			}
			this.listenTo(c.model, `manualChange:${c.modelAttr}` , () =>  {
				this.validationContext.check(input);
			});
		});
	},

	initializeMultilingualInputs: function () {
		var that = this;
		this.$('div [data-is-string-multilingual]')
			.not('.modal [data-is-string-multilingual]')
			.not('div.html-string')
			.not('table [data-is-string-multilingual]')
			.each(function (event) {
				var element = $(this).find('.form-control');
				if ($(this).hasClass('form-control')) {
					element = $(this);
				}
				var model = getChildModel(that.model, element);
				model.model.set(model.attr,
					new MultilingualString(model.model.get(model.attr) ?
						model.model.get(model.attr).toJSON() : {}));
				let input = null;
				if ($(this).attr('data-is-string-html') == 'true') {
					input = new MultilingualHtmlInputField({
						model: model.model,
						modelAttr: model.attr,
						el: $(this),
						context: that.context
					});
				} else {
					input = new MultilingualInputField({
						model: model.model,
						modelAttr: model.attr,
						el: $(this),
						context: that.context
					});
				}
				that.addToFieldsByViewId(this, input)
				that.inputs.push(input);
			});
	},

	initializeBinaryInputs: function () {
		var that = this;
		this.$('div.file-uploader')
		.not('.modal .file-uploader')
			.each(function (event) {
				var model = getChildModel(that.model, this);
				var input = new BinaryInput({
					model: model.model,
					modelAttr: model.attr,
					el: this,
					context: that.context
				});
				that.inputs.push(input);
				that.addToFieldsByViewId(this, input)
			});
	},

	initializeTemporalAmountInputs: function () {
		var that = this;
		this.$('div[data-field].temporal-amount')
			.not('.modal div[data-field].temporal-amount')
			.each(function (event) {
				var model = getChildModel(that.model, this);
				const primitiveType = utils.getPrimitiveType($(this));
				var input;
				switch (primitiveType) {
					case PrimitiveEntityType.ZONE_OFFSET:
					case PrimitiveEntityType.DURATION:
					case PrimitiveEntityType.PERIOD:
						input = new FieldTemporalAmountInput({
							model: model.model,
							modelAttr: model.attr,
							el: this,
							context: that.context,
						});
						break;
					default:
						throw new Error('Wrong entity type!');
						break;
				}
				that.addToFieldsByViewId(this, input)
				that.inputs.push(input);
			});
	},

	initializeSelects: function () {
		var that = this;
		this.$('select[data-field][data-collection!="true"]')
			.not('.modal select').each(function (event) {
				var model = getChildModel(that.model, this);
				const primitiveType = utils.getPrimitiveType($(this));
				const fieldKind = $(this).attr('data-field-kind');
				var select;
				if (primitiveType === PrimitiveEntityType.DAY_OF_WEEK) {
					select = new DayOfWeekSelect({
						model: model.model,
						modelAttr: model.attr,
						el: this,
						context: that.context
					});
				} else if (primitiveType === PrimitiveEntityType.META_OBJECT_STATE) {
					select = new MetaObjectStateSelect({
						model: model.model,
						modelAttr: model.attr,
						el: this,
						context: that.context
					})
				} else if (fieldKind === FieldKind.REGULAR) {
					select = new FieldSelect({
						model: model.model,
						modelAttr: model.attr,
						el: this,
						parentField: model.parentField,
						context: that.context
					});
				} else if (fieldKind === FieldKind.DYNAMIC) {
					select = new FieldDynamic({
						model: model.model,
						modelAttr: model.attr,
						el: this,
						parentField: model.parentField,
						context: that.context
					});
				} else {
					select = new FieldMultiselect({
						model: model.model,
						modelAttr: model.attr,
						el: this,
						context: that.context
					});
				}
				that.addToFieldsByViewId(this, select)
				that.selects.push(select);
			});
	},

	initializeTables: function () {
		var that = this;
		this.$('.data-table[data-is-embedded="false"][data-is-virtual="false"]').each(function (event) {
			const viewItemId = that.getViewItemId(this)
			var model = getChildModel(that.model, this);
			const field = app.fields.get(this.getAttribute('data-field-id'))
			let threshold = parseInt(this.getAttribute('card-table-threshold'))
			if (threshold == NaN) {
				threshold = 768
			}
			if (this.classList.contains('skip-xs') && window.screen.width < threshold ) {
				let toolbar = [].find.call(this.parentNode.children, (c) => {return c.classList.contains('toolbar' + field.id)})
				if (toolbar) toolbar.style.display = 'none'
				this.style.display = 'none'
				return
			}
			var table = new FieldTable({
				model: model.model,
				modelAttr: model.attr,
				el: this,
				context: that.context,
			});
			that.tables.push(table);
			that.fieldsByViewId.set(viewItemId, table)
		});
	},

	initializeEmbeddedTables: function () {
		var that = this;
		this.$('.data-table[data-is-embedded="true"]').each(function (event) {
			const field = app.fields.get(this.getAttribute('data-field-id'))
			let threshold = parseInt(this.getAttribute('card-table-threshold'))
			if (threshold == NaN) {
				threshold = 768
			}
			if (this.classList.contains('skip-xs') && window.screen.width < threshold ) {
				let toolbar = [].find.call(this.parentNode.children, (c) => {return c.classList.contains('toolbar' + field.id)})
				if (toolbar) toolbar.style.display = 'none'
				this.style.display = 'none'
				return
			}
			const viewItemId = that.getViewItemId(this)
			var model = getChildModel(that.model, this);
			var table = new EmbeddedFieldTable({
				model: model.model,
				modelAttr: model.attr,
				el: this,
				parent: that,
				context: that.context
			});
			that.embeddedTables.push(table);
			that.fieldsByViewId.set(viewItemId, table)
		});
	},

	initializeVirtualTables: function () {
		var that = this;
		this.$('.data-table[data-is-virtual="true"]').each(function (event) {
			const field = app.fields.get(this.getAttribute('data-field-id'))
			let threshold = parseInt(this.getAttribute('card-table-threshold'))
			if (threshold == NaN) {
				threshold = 768
			}
			if (this.classList.contains('skip-xs') && window.screen.width < threshold ) {
				let toolbar = [].find.call(this.parentNode.children, (c) => {return c.classList.contains('toolbar' + field.id)})
				if (toolbar) toolbar.style.display = 'none'
				this.style.display = 'none'
				return
			}
			const viewItemId = that.getViewItemId(this)
			var model = getChildModel(that.model, this);
			let type =  app.types.get($(this).attr('data-entity-type-id'));
			var table = new VirtualCollection({
				el: this,
				isDocuments: type.kind() == TypeKind.DOCUMENT,
				folders: $(this).closest('.form-group.collection').find('.folders-tree'),
				caption: $(this).closest('.form-group.collection').find('.caption'),
				hasMetaObject: type.hasMetaObject(),
				typeId: type.id,
				treeViewKind: $(this).attr('data-tree-view-kind'),
				fieldId: $(this).attr('data-field'),
				context: that.context
			});

			that.virtualTables.push(table);
			that.fieldsByViewId.set(viewItemId, table)
		});
	},
	
	initializeDocumentTimelineTable: function () {
		var that = this;
		this.$('.data-table[documenttimeline="true"]').each(function (event) {
			var table = new TimelineTable({
				el: this,
				initFromPage:true,
				context: that.context
			});
			that.documentTimeline = table
		});
	},	

	initializeTriggerButtons: function () {
		var that = this;
		if (!this.initFormToolbarTriggers && !this.$el.has(this.context.wizardView.$toolbar).length) {
			this.initFormToolbarTriggers = true;
			this.context.wizardView.$toolbar.find('[data-custom_event]').each(function () {
				new TriggerButton({
					el: this,
					context: that.context
				});
			});
		}
		this.$('[data-custom_event]').each(function () {
			var button = new TriggerButton({
				el: this,
				context: that.context
			});
			that.triggerButtons.push(button)
			that.addToFieldsByViewId(this, button)
		});
	},

	initializeTabContainers: function () {
		var that = this;
		this.$('.nav-tabs-custom').each(function () {
			let tab = new TabContainer({
				el: this,
				viewId: that.context.viewId
			});
			that.tabs.push(tab)
			tab.tabs.filter((t) => {
				return t.viewItemId != null
			}).forEach((t) => {
				that.subTabs[t.viewItemId] = t
			})
		});
	},

	initializeGroupBoxes: function() {
		let that = this;
		this.$('.box').each(function () {
			let groupBox = new GroupBox({
				el: this
			});
			that.groupBoxes.push(groupBox);
		});
	},

	_eachControl: function (func) {
		_.each(this.inputs, func);
		_.each(this.selects, func);
		_.each(this.tables, func);
		_.each(this.embeddedTables, func);
		_.each(this.virtualTables, func);
		_.each(this.filters, func);
		_.each(this.widgets, func)
	},

	enable: function () {
		this._eachControl(function (control) {
			if (!(control.isDisabledInFormBuilder && control.isDisabledInFormBuilder())) control.enable();
		})
	},

	disable: function () {
		this._eachControl(function (control) {
			control.disable();
		});
	},

	render: function () {
		this._eachControl(function (control) {
			control.render();
		});
	},

	destroy: function () {
		this._eachControl(c => {
			c.undelegateEvents && c.undelegateEvents();
			c.remove && c.remove();
		});
		this.undelegateEvents();
		this.remove();
	},

	onInitComplete: function () {
		this.$el.find('.box').find('.overlay').remove();
	},

	setDefaultFocus: function (readOnlyMode) {

		let that = this;

		let firstTabIndex = -1;
		let firstFocusable = null;

		if (readOnlyMode) {
			firstFocusable = $('.edit:not(.hidden):not(.disabled)').first();
		}

		if (!firstFocusable) {
			this.$('.form-group:not(.hidden):not(.disable-all)').each(function() {
				$(this).find('[data-field]').each(function() {

						const focusable = that.findFocusable(this);

						if (focusable) {
							const tabIndex = focusable.attr('tabindex') || 0;
							if (tabIndex > -1 && (firstFocusable == null || tabIndex < firstTabIndex)) {
									firstTabIndex = tabIndex;
									firstFocusable = focusable;
									if (firstTabIndex == 0) {
										return;
									}
							}
						}
					});
			});
		}

		if (firstFocusable) {
			firstFocusable.focus();
		}
	},

	findFocusable: function (element) {
		//TODO: This logic should be moved to corresponding control components
		if ($(element).is('select')) {
			const tabIndex = $(element).attr('tabindex-orig') || $(element).attr('tabindex') || 0;
			const focusable = $(element).parent().find('.select2-selection').first();
			if (focusable) focusable.attr('tabindex', tabIndex);
			return focusable;
		}
		else if ($(element).is('div.file-uploader, .dropzone')) {
			return $(element);
		}
		else if ($(element).find('.input-part').length > 0) {
			return $(element).find('input').first();
		}
		else if ($(element).parent().is('.checkbox, .flex')) {
			return $(element).parent().find('input.cmn-toggle-round+label').first();
		}
		else if ($(element).is('input')) {
			return $(element);
		}
		else {
			return null;
		}
	},

	startUnsavedChangesTracking: function() {
		if (!this.isTrackingOfUnsavedChangesBlocked) {
			this.originalModel = JSON.parse(JSON.stringify(this.model));
			this.isTrackingOfUnsavedChangesOn = true;
		}
	},

	suppressUnsavedChangesTracking: function() {
		this.isTrackingOfUnsavedChangesOn = false;
	},

	resumeUnsavedChangesTracking: function() {
		this.isTrackingOfUnsavedChangesOn = true;
	},

	blockUnsavedChangesTracking: function() {
		this.isTrackingOfUnsavedChangesBlocked = true;
	},

	show() {
		this.$el.show();
	},

	hide() {
		this.$el.hide();
	}

});

export default FormView;
